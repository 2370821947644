<template>
    <div>
        <!-- start page title -->
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Sound Tags</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Sound Tags
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Sound Tags</h4>
                    </div>
                    <div class="card-body">
                        <validation-errors></validation-errors>
                        <form @submit.prevent="createSoundTag()">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label for="title" class="form-label">Title</label>
                                    <input class="form-control" type="text" id="title"
                                           placeholder="e.g tungrill beat.com" v-model="tag.title">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label for="status" class="form-label">Status</label>
                                    <select v-model="tag.is_active" class="form-control">
                                        <option disabled selected>Choose status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label for="file" class="form-label">Sound Tag File(<small>mp3</small>)</label>
                                    <file-pond
                                        v-if="true"
                                        name="audio"
                                        ref="soundTagFile"
                                        label-idle="Drag & drop blog Mp3 file"
                                        accepted-file-types="audio/mpeg"
                                        data-max-file-size="5MB"
                                        :files="soundTagFile"
                                    />
                            </div>
                        </div>



                            <div class="row">
                                <div class="col-md-4 col-xs-12">
                                    <button type="submit" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import {VueEditor} from "vue2-editor";
import ValidationErrors from "../../Shared/ValidationErrors";

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);

export default {
    metaInfo: {title: 'Sound Tags'},
    props: ['tagData'],
    layout: Layout,
    components: {
        VueEditor,
        FilePond,
        ValidationErrors
    },
    data: () => ({
        tag: {},
        saving: false,
        soundTagFile: [],
    }),
    methods: {

        async createSoundTag() {
            this.saving = true;
            let vm = this;

            try {
                 const formData = new FormData();
                const uploadedFiles = this.$refs.soundTagFile.getFiles();

                if (uploadedFiles.length > 0) {
                    uploadedFiles.forEach(file => formData.append('sound_tag', file.file));
                }

                // add post key value pair
                Object.keys(this.tag).forEach(tag => formData.append(tag, this.tag[tag]));

                this.$inertia.post(route('admin.tag.store'), formData, {
                    preserveScroll: true,
                    onError: () => vm.saving = false,
                    onSuccess: () => vm.saving = false,
                })

            } catch (e) {
                console.log(e);
                this.saving = false
            }
        },

        handleFileUpload(event) {
            const file = event.target.files[0];
            this.tag.file_url = URL.createObjectURL(file);
        }
    },

}
</script>

<style scoped>

</style>
