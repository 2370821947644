<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
            </div>
        </div>


         <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Admins</h4>
                </div>
                <div class="card-body">
                        <vue-good-table :columns="columns" :rows="rows" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: { field: 'created_at', type: 'desc' }
                        }" :pagination-options="{
                        enabled: true,
                        mode: 'pages',
                        position: 'top',
                        perPage: 10
                        }">
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'action'">
                                <ActionLinks :actionParams="{
                                    title: 'admin.admins',
                                    id: props.row.id,
                                    showView: true,
                                    showEdit: true,
                                    showDelete: true
                                }">
                                </ActionLinks>

                            </span>

                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>   
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ActionLinks from '@/Shared/ActionLinks';
import Paginate from '@/Shared/Paginate';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';
import { Router } from '@inertiajs/inertia-vue';


export default {
    metaInfo: { title: 'admins' },
    layout: Layout,
    props: ['admins'],
    components: {
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
        Paginate,
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.admins.create",
                linkPage: "Create Admin",
                currentPage: "Admins",
            },
            rows: this.admins.data,
            links: {},
            columns: [
                { label: 'Date Added', field: 'created_at' },
                { label: 'First Name', field: 'first_name' },
                { label: 'Last Name', field: 'last_name' },
                { label: 'Email', field: 'email' },
                { label: 'Action', field: 'action' },
            ]
        }
    },
    mounted() {
        this.rows = this.admins.data;
        this.links = this.admins.links;
        // console.log(this.alladmins);
    },






}
</script>