<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div  class="card-body">

                    <form @submit.prevent="submit" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-12">
                                <validation-errors></validation-errors>
                            </div>
                        </div>

                       <div class="row">
                                <div class="form-group col-sm-6">
                                    <div class="controls">
                                        <label>Title(60 Characters max.)</label>
                                        <input type="text" v-model="track.title" class="form-control"
                                               placeholder="Title"
                                               required>
                                        <!-- <div v-if="Track.errors.title" class="text-danger text-small">
                                            {{ Track.errors.title }}
                                        </div> -->
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Description</label>
                                        <input type="text" v-model="track.description" class="form-control"
                                               placeholder="Description"
                                               required>
                                        <!-- <div v-if="Track.errors.description" class="text-danger text-small">
                                            {{ Track.errors.description }}
                                        </div> -->
                                    </div>
                                </div>
                        </div>

                        <div class="row">
                                <div class="form-group col-sm-6">
                                    <div class="controls">
                                        <label>Upload Artwork File</label>
                                        <file-pond
                                            v-if="true"
                                            name="test"
                                            ref="artworkFile"
                                            label-idle="Drag & drop artWork File"
                                            data-allow-reorder="true"
                                            data-max-file-size="2MB"
                                            :files="artworkFile"
                                            v-bind:allow-multiple="true"
                                        />
                                    </div>
                                </div>
                                
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Upload Wav File</label>
                                        <file-pond
                                        v-if="true"
                                            name="test"
                                            ref="wavFile"
                                            label-idle="Drag & drop Wav file"
                                            data-allow-reorder="true"
                                            data-max-file-size="2MB"
                                            :files="wavFile"
                                            v-bind:allow-multiple="true"
                                         />
                                    </div>
                                </div>    
                        </div>

                        <div class="row">
                                <div class="form-group col-sm-6">
                                    <div class="controls">
                                        <label>Upload MP3 file only </label>
                                                <file-pond
                                                v-if="true"
                                                name="test"
                                                ref="mp3File"
                                                label-idle="Drag & drop mp3 file"
                                                accepted-file-types="audio/mpeg, audio/mpa, audio/mpa-robust"
                                                data-allow-reorder="true"
                                                data-max-file-size="2MB"
                                                :files="mp3File"
                                                v-bind:allow-multiple="true"
                                                />
                                    </div>
                                </div>
                                <div class="form-group col-sm-6">
                                    <div class="controls">
                                        <label>Upload Track( Zip File)</label>
                                                <file-pond
                                                v-if="true"
                                                name="test"
                                                ref="trackFile"
                                                label-idle="Drag & drop Track zip file"
                                                accepted-file-types="application/zip"
                                                data-allow-reorder="true"
                                                data-max-file-size="2MB"
                                                :files="trackFile"
                                                v-bind:allow-multiple="true"

                                                />
                                    </div>
                                </div>
                        </div>

                        <div class="row">
                                <div class="form-group col-sm-6">
                                    <div class="controls">
                                        <label class="form-label">Creator</label>
                                        <select v-model="track.user_id" id="customSelect" class="custom-select block" required>
                                            <option value="" selected>Select a creator</option>
                                            <option v-for="creator in track_params.creators" :key="creator.id" :value="creator.id">
                                                {{ creator.name }}
                                            </option>
                                        </select>
                                        <!-- <span v-if="Track.errors.creator"
                                              class="text-danger text-small">{{ Track.errors.creator }}
                                        </span> -->
                                    </div>
                                </div>
             
                                <div class="form-group col-sm-6">
                                    <div class="controls">
                                        <label class="form-label">Primary Genre*</label>
                                        <select v-model="track.genre_id" id="customSelect" class="custom-select block" required>
                                            <option value="" selected>Select a primary genre</option>
                                            <option v-for="primarygenre in track_params.primarygenres" :key="primarygenre.id" :value="primarygenre.id">
                                                {{ primarygenre.name }}
                                            </option>
                                        </select>
                                        <!-- <span v-if="Track.errors.primarygenre"
                                          class="text-danger text-small">{{ Track.errors.primarygenre }}</span> -->
                                    </div>
                                </div>
                           </div>

                           <div class="row">
                                <div class="form-group col-sm-6">
                                    <div class="controls">
                                        <label class="form-label">Sub Genre</label>
                                        <select v-model="track.sub_genre_id"  id="customSelect" class="custom-select block">
                                            <option value="" selected>Select a subgenre</option>
                                            <option v-for="subgenre in track_params.subgenres" :key="subgenre.id" :value="subgenre.id">
                                                {{ subgenre.name }}
                                            </option>
                                        </select>
                                        <!-- <span v-if="Track.errors.subgenre"
                                              class="text-danger text-small">{{ Track.errors.subgenre }}</span> -->
                                    </div>
                                </div>
                          </div>

                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                               <button type="submit"  class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1" :disabled="saving">{{  saving ? 'In progress. Please wait...':'Save' }}</button>
                            </div>
                    
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ValidationErrors from "../../Shared/ValidationErrors";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {VueEditor} from "vue2-editor";
// import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';


const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    // FilePondPluginFilePoster
    );



export default {
    metaInfo: {title: 'Create Track'},
    name: 'TrackCreate',
    layout: Layout,
    props: ['track_params'],
    components: {
        BreadCrumbs,
        ValidationErrors,
        FilePond,
        Link,
    },
    data() {
        return {
            breadCrumbsDetails: {
            link: "admin.tracks.index",
            linkPage: "All Tracks",
            currentPage: "Create Track",
           },
            track: {
                title: null,
                description: null,
                genre_id: null,
                sub_genre_id: null,
                user_id: null,
               
            },
            saving: false,
            artworkFile: [],
            mp3File: [],
            trackFile: [],
            wavFile: [],
            // myFiles: []

        }
    },
    methods: {
        submit(){
                const formData = new FormData();
                const artworkUploaded = this.$refs.artworkFile.getFiles();
                const mp3Uploaded = this.$refs.mp3File.getFiles();
                const trackUploaded = this.$refs.trackFile.getFiles();
                const wavUploaded = this.$refs.wavFile.getFiles();

                if (artworkUploaded.length > 0) {
                    artworkUploaded.forEach(file => formData.append('artworkfile', file.file));
                }

                if (mp3Uploaded.length > 0) {
                    mp3Uploaded.forEach(file => formData.append('mp3file', file.file));
                }
                if (trackUploaded.length > 0) {
                    trackUploaded.forEach(file => formData.append('trackfile', file.file));
                }

                if (wavUploaded.length > 0) {
                    wavUploaded.forEach(file => formData.append('wavfile', file.file));
                }
             
                this.$inertia.post(route('admin.tracks.store',this.track), formData, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.saving = false;
                    },
                    onBefore: () => {
                        this.saving = true;
                    },    
                    onError: () => {
                        this.saving = false;
                    }         
                });

        },
        handleFilePondInit(){
              
        },
        handleFilePondLoad(response){
               
        },


    }

 }

</script>
