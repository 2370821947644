<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-body">
                    <form
                        @submit.prevent="submit"
                        enctype="multipart/form-data"
                    >
                        <div class="row">
                            <div class="col-12">
                                <validation-errors></validation-errors>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <div class="controls">
                                    <label>Name of Plan</label>
                                    <input
                                        type="text"
                                        v-model="plans.name"
                                        class="form-control"
                                        placeholder="Enter of Plan"
                                    />
                                </div>
                            </div>
<!--
                            <div class="form-group col-md-6">
                                <div class="controls">
                                    <label>Features</label>
                                    <textarea
                                        class="form-control"
                                        name="meta_description"
                                        id="meta_description"
                                        cols="30"
                                        rows="2`"
                                        v-model="plans.features"
                                        placeholder="Feautures"
                                    >
                                    </textarea>
                                </div>
                            </div> -->

                        </div>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <div class="controls">
                                    <label>Price</label>
                                    <input
                                        type="number"
                                        v-model="plans.price"
                                        class="form-control"
                                        placeholder="Price"
                                    />
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <div class="controls">
                                    <label>Duration</label>
                                    <select
                                        v-model="plans.duration"
                                        id=""
                                        class="form-control"
                                    >
                                        <option value="" selected disabled>
                                            Select Duration
                                        </option>
                                        <option value="1">Monthly</option>
                                        <!-- <option value="12">Yearly</option> -->
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <div class="controls">
                                    <label>Discount(Yearly)</label>
                                    <input
                                        type="number"
                                        v-model="plans.discount"
                                        class="form-control"
                                        placeholder="Example 2%"
                                    />
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <div class="controls">
                                    <label>Status</label>
                                    <select
                                        v-model="plans.status"
                                        id=""
                                        class="form-control"
                                    >
                                        <option value="" selected>
                                            Select Status
                                        </option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="form-group col-md-3">
                                <div class="controls">
                                    <label>Tracks</label>
                                    <input
                                        type="text"
                                        v-model="plans.numOfTracks"
                                        class="form-control"
                                     />
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <div class="controls">
                                    <label>Albums</label>
                                    <input
                                        type="text"
                                        v-model="plans.numOfAlbums"
                                        class="form-control"
                                     />
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <div class="controls">
                                    <label>SoundKits</label>
                                    <input
                                        type="text"
                                        v-model="plans.numOfSoundkits"
                                        class="form-control"
                                     />
                                </div>
                            </div>

                            <div class="form-group col-md-3">
                                <div class="controls">
                                    <label>Playlists</label>
                                    <input
                                        type="text"
                                        v-model="plans.numOfPlaylists"
                                        class="form-control"
                                     />
                                </div>
                            </div>
                        </div>

                        <!-- </div> -->

                        <div
                            class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1"
                        >
                            <button
                                type="submit"
                                class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from "@/Shared/Layout";
import BreadCrumbs from "@/Shared/BreadCrumbs";
import ValidationErrors from "../../Shared/ValidationErrors";
import { Link } from "@inertiajs/inertia-vue";

export default {
    metaInfo: { title: "Create Plan" },
    name: "CreatePlan",
    layout: Layout,
    props: [],
    components: {
        BreadCrumbs,
        ValidationErrors,
        Link,
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.plans.index",
                linkPage: "All Plan",
                currentPage: "Create Plan",
            },
            plans: {
                name: null,
                price: null,
                features: null,
                discount: null,
                durations: null,
                status: null,
                numOfTracks:null,
                numOfAlbums:null,
                numOfSoundkits:null,
                numOfPlaylists:null
            },
        };
    },
    methods: {
        submit() {
            const formData = new FormData();

            this.$inertia.post(
                route("admin.plans.store", this.plans),
                formData
            );
        },
    },
};
</script>
