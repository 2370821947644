<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div  class="card-body">

                    <form @submit.prevent="submit" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-12">
                                <validation-errors></validation-errors>
                            </div>
                        </div>


                        <div class="row">          
                            <!-- <div class="col-6 col-sm-6"> -->
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Title(60 Characters max.)</label>
                                        <input type="text" v-model="soundkit.title" class="form-control"
                                               placeholder="Title"
                                               required>
                                        <!-- <div v-if="soundkit.errors.title" class="text-danger text-small">
                                            {{ soundkit.errors.title }}
                                        </div> -->
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Description</label>
                                        <input type="text" v-model="soundkit.description" class="form-control"
                                               placeholder="Description"
                                               required>
                                        <!-- <div v-if="soundkit.errors.description" class="text-danger text-small">
                                            {{ soundkit.errors.description }}
                                        </div> -->
                                    </div>
                                </div>
                            <!-- </div> -->
                        </div>

                        <div class="row">

                            <!-- <div class="col-12 col-sm-12"> -->
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Upload Artwork</label>
                                        <file-pond
                                        v-if="true"
                                        name="artwork"
                                        ref="artworkFile"
                                        label-idle="Drag & drop artwork image"
                                        accepted-file-types="image/jpeg, image/png, image/gif"
                                        data-allow-reorder="true"
                                        data-max-file-size="2MB"
                                        :files="artworkFile"
                                        v-bind:allow-multiple="true"

                                    />
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <div class="controls">
                                        <label>File Url</label>
                                        <input type="text" class="form-control"
                                               placeholder="File url"
                                               >
                                    </div>
                                </div> -->
                            <!-- </div> -->

                             <!-- <div class="col-12 col-sm-12"> -->
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Upload MP3 file only </label>
                                                <file-pond
                                                v-if="true"
                                                name="mp3"
                                                ref="mp3File"
                                                label-idle="Drag & drop mp3 file"
                                                accepted-file-types="audio/mpeg, audio/mpa, audio/mpa-robust"
                                                data-allow-reorder="true"
                                                data-max-file-size="6MB"
                                                :files="mp3File"
                                                v-bind:allow-multiple="true"
                                                />
                                    </div>
                                </div>
                        </div>

                        <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Upload Soundkit( Zip File)</label>
                                                <file-pond
                                                v-if="true"
                                                name="soundkitzip"
                                                ref="soundkitFile"
                                                label-idle="Drag & drop soundkit zip file"
                                                accepted-file-types="application/zip"
                                                data-allow-reorder="true"
                                                data-max-file-size="2MB"
                                                :files="soundkitFile"
                                                v-bind:allow-multiple="true"

                                                />
                                    </div>
                                </div>
                            

                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Price</label>
                                        <input type="number" v-model="soundkit.price" class="form-control"
                                               placeholder="Price"
                                               required>
                                        <!-- <div v-if="soundkit.errors.price" class="text-danger text-small">
                                            {{ soundkit.errors.price }}
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label class="form-label">Creator</label>
                                        <select v-model="soundkit.user_id" id="" class="form-control" required>
                                            <option value="" selected>Select a creator</option>
                                            <option v-for="creator in soundkit_params.creators" :key="creator.id" :value="creator.id">
                                                {{ creator.name }}
                                            </option>
                                        </select>
                                        <!-- <span v-if="soundkit.errors.creator"
                                              class="text-danger text-small">{{ soundkit.errors.creator }}
                                        </span> -->
                                    </div>
                                </div>
                            

                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label class="form-label">Primary Genre*</label>
                                        <select v-model="soundkit.genre_id" id="customSelect" class="custom-select block" required>
                                            <option value=""  selected>Select a primary genre</option>
                                            <option v-for="primarygenre in soundkit_params.primarygenres" :key="primarygenre.id" :value="primarygenre.id">
                                                {{ primarygenre.name }}
                                            </option>
                                        </select>
                                        <!-- <span v-if="soundkit.errors.primarygenre"
                                          class="text-danger text-small">{{ soundkit.errors.primarygenre }}</span> -->
                                    </div>
                                </div>
                            </div>
                            
                            <div class="'row'">
                               <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label class="form-label">Sub Genre</label>
                                        <select v-model="soundkit.sub_genre_id" id="customSelect" class="custom-select block">
                                            <option value="" selected>Select a subgenre</option>
                                            <option v-for="subgenre in soundkit_params.subgenres" :key="subgenre.id" :value="subgenre.id">
                                                {{ subgenre.name }}
                                            </option>
                                        </select>
                                        <!-- <span v-if="soundkit.errors.subgenre"
                                              class="text-danger text-small">{{ soundkit.errors.subgenre }}</span> -->
                                    </div>
                                </div>
                               <!-- <div class="form-group col-md-6">
                                </div> -->
                            </div>

                            
                            <div class="row">
                                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                           <button type="submit"  class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1" :disabled="saving">{{  saving ? 'In progress. Please wait...':'Save' }}</button>
                                        </div>
                            </div>
                            
                        <!-- </div> -->
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ValidationErrors from "../../Shared/ValidationErrors";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {VueEditor} from "vue2-editor";


// import FilePondPluginFilePoster from 'filepond-plugin-file-poster';


import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';


const FilePond = vueFilePond(
        FilePondPluginFileValidateType,
        FilePondPluginImagePreview,
        // FilePondPluginFilePoster
    );

export default {
    metaInfo: {title: 'Create Soundkit'},
    name: 'SoundkitCreate',
    layout: Layout,
    props: ['soundkit_params'],
    components: {
        BreadCrumbs,
        ValidationErrors,
        FilePond,
        Link,
    },
    data() {
        return {
            breadCrumbsDetails: {
            link: "admin.soundkits.index",
            linkPage: "All SoundKits",
            currentPage: "Create SoundKit",
           },
            soundkit: {
                title: null,
                user_id: null,
                description: null,
                price: null,
                genre_id: null,
                sub_genre_id: null,
            },
            saving: false,
            artworkFile: [],
            mp3File: [],
            soundkitFile: [],
            // myFiles: []

        }
    },
    methods: {
        submit(){
                const formData = new FormData();
                const artworkUploaded = this.$refs.artworkFile.getFiles();
                const mp3Uploaded = this.$refs.mp3File.getFiles();
                const soundkitUploaded = this.$refs.soundkitFile.getFiles();

                // formData.append('artworkfile',artworkUploaded);
                // formData.append('mp3file',mp3Uploaded);
                // formData.append('soundkitfile',soundkitUploaded);

                // if (artworkUploaded.length > 0) {
                //     artworkUploaded.forEach(function(file) {
                //             // console.log(file.file);
                //             this.soundkit.artworkfile.append(file.file);
                //     })
                //     formData.append('artworkfile', this.soundkit.artworkfile);


                // }


                if (artworkUploaded.length > 0) {
                    artworkUploaded.forEach(file => formData.append('artwork_url', file.file));
                    // console.log(file.file);
                }

                if (mp3Uploaded.length > 0) {
                    mp3Uploaded.forEach(file => formData.append('mp3file', file.file));
                    // console.log(file.file);
                }
                if (soundkitUploaded.length > 0) {
                    soundkitUploaded.forEach(file => formData.append('soundkitfile', file.file));
                    // console.log(file.file);
                }
                // console.log(uploadedFiles[0].file);

                // add post key value pair
                // Object.keys(this.album).forEach(album => formData.append(album, this.album[album]));


                this.$inertia.post(route('admin.soundkits.store',this.soundkit), formData, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.saving = false;
                    },
                    onBefore: () => {
                        this.saving = true;
                    },    
                    onError: () => {
                        this.saving = false;
                    }         
                });

        },
        handleFilePondInit(){
        },
        handleFilePondLoad(response){
                // this.form.myFiles = response;
        },


    }

 }

</script>
