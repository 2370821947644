<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails"/>
            </div>
        </div>

        <div class="content-body">
            <section class="tracks-view">

                <!-- tracks view media object ends -->
                <!-- tracks view card data start -->
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Name:</td>
                                            <td class="btn btn-primary">{{ plan.name }}</td>
                                        </tr>
                                        <tr>
                                            <td>Price:</td>
                                            <td class="users-view-latest-activity">{{ plan.price }}</td>
                                        </tr>
                                        <tr v-if="plan.discount">
                                            <td>Discount:</td>
                                            <td class="users-view-role"> {{ plan.discount }}%</td>
                                        </tr>
                                        <tr>
                                            <td>Duration:</td>
                                            <td class="users-view-role"> {{ plan.duration }} Month</td>
                                        </tr>
                                        <tr>
                                            <td>Status:</td>
                                            <td class="users-view-role"> {{ plan.status ? 'Active' : 'Inactive' }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- tracks view card data ends -->
                <!-- tracks view card details start -->
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">

                            <h5 class="mb-1"><i class="feather icon-info"></i> Details</h5>
                            <div class="row">
                                <div class="col-12 col-md-10">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Number of Tracks Allowed:</td>
                                            <td>{{ plan.allowed_total_tracks }}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Albums Allowed:</td>
                                            <td>{{ plan.allowed_total_albums }}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of SoundKit Allowed:</td>
                                            <td class="users-view-role">{{ plan.allowed_total_sound_kits }}</td>
                                        </tr>

                                        <tr>
                                            <td>Number of Tracks Playlists:</td>
                                            <td class="users-view-role">{{ plan.allowed_total_playlists }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- tracks view card details ends -->

                <div class="card">
                    <div class="card-content">
                        <div class="card-body">

                            <h5 class="mb-1"><i class="feather icon-info"></i> More Info</h5>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Feautures:</td>
                                            <td class="users-view-name"> {{
                                                    plan.features
                                                }}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Date Created:</td>
                                            <td class="users-view-email">{{ plan.created_at }}</td>
                                        </tr>
                                        <tr>
                                            <td>Last Activity:</td>
                                            <td class="users-view-email">{{ plan.updated_at }}</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import {Inertia} from '@inertiajs/inertia';
import {Link} from '@inertiajs/inertia-vue';

export default {
    metaInfo: {title: 'Show Plan'},
    props: ['plan'],
    layout: Layout,
    components: {
        BreadCrumbs,
        Link,
        Inertia
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.plans.index",
                linkPage: "All Plan",
                currentPage: "View Plan",
            },
        }
    },


}
</script>

<style scoped>

</style>
