<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
            </div>
        </div>
        <div class="">
            <!-- <div class="card-header">
         
                       <div class="row">
                                <div class="form-group col-md-10  ml-1">
                                    <div class="controls">
                                        <input   type="text"  class="form-control"
                                               placeholder="Search by title"
                                               required>   
                                    </div>
                                </div>
                            
                        </div>   
        
            </div> -->

                
                <div class="card card-header table-responsive">

                  <h4 class="card-title mb-1">Albums</h4>
                
                        <div class="row">
                                        <div class="form-group col-md-12">
                                            <div class="controls">
                                                <input v-model="search"   type="text"  class="form-control"
                                                    placeholder="Search by title"
                                                    required>   
                                            </div>
                                        </div>
                        </div>  


                        <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                        <div class="heading-elements">
                            <ul class="list-inline mb-0">
                                <!-- <li><a data-action="reload"><i class="feather icon-rotate-cw"></i></a></li> -->
                                <li><a data-action="expand"><i class="feather icon-maximize"></i></a></li>
                            
                            </ul>
                        </div>

                        <table class="table table-bordered table-striped ">
                                    <thead >
                                        <tr class="p-2">
                                            <th>SN</th>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Producer</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, index) of rows" :key="row.id">
                                            <th scope="row">{{ index + 1 }}</th>
                                            <td>{{ row.created_at }}</td>
                                            <td>{{ row.title }}</td>
                                            <td>{{ row.creator }}</td>
                                            <td>
                                                
                                                <ActionLinks :actionParams="{
                                                title: 'admin.albums',
                                                id: row.id,
                                                showView: true,
                                                showEdit: false,
                                                showDelete: true
                                                }">
                                                </ActionLinks>   

                                            
                                            </td>
                                        </tr>
                                    
                                    </tbody>
                        </table>

                        <div class="footer" style="float:right;">
                            <Pagination :links="linkData" />
                        </div>
                </div>
        </div>
               
        
        </div>


</template>
<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ActionLinks from '@/Shared/ActionLinks';
import Pagination from '@/Shared/Paginate';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';
import { Router } from '@inertiajs/inertia-vue';
// import {ref, watch} from 'vue';
// import debounce from 'lodash/debounce';




export default {
    metaInfo: {title: 'Albums'},
    props: ['albums','filters'],
    layout: Layout,
    components:{
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
        Pagination,
    },
    data() {
  
    return {
           breadCrumbsDetails: {
                link: "admin.albums.index",
                linkPage: "All Album",
                currentPage: "Albums",
           },
           rows: {},
           linkData: {},
           search: null,
           columns: [
            {label: 'Date Added', field: 'created_at'},
            {label: 'Title', field: 'title'},
            {label: 'Producer', field: 'creator'},
            {label: 'Status', field: 'status'},
            {label: 'Action', field: 'action'},
            
           ]
        }
    },
    mounted() {
             this.rows = this.albums.data;
             this.linkData = this.albums.links;
    },
    methods:{
        // function getRows(){
        // }
    }, 
    watch: {
        search: async function (val) {

            // console.log(val);
           this.$inertia.get('/albums',{search:val}, {
                preserveState:true,
                // replace:true
            });

            
            // this.rows = records.data
            // this.rows = record.data
            //  const funcc = debounce(function() {
            //     console.log('triggered');
            //     //       Inertia.get('/albums',{search:val}, {
            //     //       preserveState:true,
            //     //      replace:true
            //     //  })
            //   }, 250);
            //  console.log(funcc);

            
        },
    }

  

    // watch: (search, debounce(function(value){
	// 		console.log('triggered');
	// 		// Inertia.get('/clients',{search:value}, {
	// 		// preserveState:true,
	// 		// replace:true
    //         // })
    // },500))

    // watch: {
    //     reloadData(newValue, oldValue) {
    //     if (newValue) {
    //         this.rows =  this.$inertia.get(route('admin.users.getAccountChangeRequests')); 
    //         console.log('testing');
    //     }
    //     },
    // },
}
</script>
