var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-header row"},[_c('div',{staticClass:"content-header-left col-md-6 col-12 mb-2"},[_c('BreadCrumbs',{attrs:{"crumbsParams":_vm.breadCrumbsDetails}})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"card card-style"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-xl-6 col-sm-12 col-6"},[_vm._m(0),_vm._v(" "),_c('h3',[_vm._v(_vm._s(_vm.wallet_balance))])])])])])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"card card-style"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-xl-6 col-sm-12 col-6"},[_vm._m(1),_vm._v(" "),_c('h3',[_vm._v("\n                                "+_vm._s(_vm.total_transaction)+"\n                            ")])])])])])])]),_vm._v(" "),_c('div',{staticClass:"content-body"},[_c('section',{staticClass:"card"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"compactMode":"","search-options":{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: {
                            field: 'date_created',
                            type: 'desc',
                        },
                    },"pagination-options":{
                        enabled: true,
                        mode: 'pages',
                        position: 'top',
                        perPage: 10,
                    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return undefined}}])})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-items":"baseline","align-items":"center","width":"300px","padding-bottom":"6px"}},[_c('i',{staticClass:"fa fa-money customize-icon font-large-1 mr-1"}),_vm._v(" "),_c('h1',{staticStyle:{"font-size":"20px","font-weight":"bold"}},[_vm._v("\n                                    Wallet Balance\n                                ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-items":"baseline","align-items":"center","width":"300px","padding-bottom":"6px"}},[_c('i',{staticClass:"icon icon-bar-chart customize-icon font-large-1 mr-1"}),_vm._v(" "),_c('h1',{staticStyle:{"font-size":"20px","font-weight":"bold"}},[_vm._v("\n                                    Total Transactions\n                                ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("Transactions")])])
}]

export { render, staticRenderFns }