<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div  class="card-body">

                    <form @submit.prevent="submit" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-12">
                                <validation-errors></validation-errors>
                            </div>
                        </div>

                        <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Title(60 Characters max.)</label>
                                        <input type="text" v-model="album.title" class="form-control"
                                               placeholder="Title"
                                               required>
                                        <!-- <div v-if="album.errors.title" class="text-danger text-small">
                                            {{ album.errors.title }}
                                        </div> -->
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Description</label>
                                        <input type="text" v-model="album.description" class="form-control"
                                               placeholder="Description"
                                               required>
                                        <!-- <div v-if="album.errors.description" class="text-danger text-small">
                                            {{ album.errors.description }}
                                        </div> -->
                                    </div>
                                </div>
                        </div>

                        <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Upload Artwork</label>
                                        <file-pond
                                        v-if="true"
                                        name="artwork"
                                        ref="artworkFile"
                                        label-idle="Drag & drop artwork image"
                                        accepted-file-types="image/jpeg, image/png, image/gif"
                                        data-allow-reorder="true"
                                        data-max-file-size="2MB"
                                        :files="artworkFile"
                                        v-bind:allow-multiple="true"

                                    />
                                    </div>
                                </div>
                           

                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label class="form-label">Producer</label>
                                        <select v-model="album.user_id"  id="customSelect" class="custom-select block" required>
                                            <option value="" selected>Select a producer</option>
                                            <option v-for="creator in album_params.creators" :key="creator.id" :value="creator.id">
                                                {{ creator.name }}
                                            </option>
                                        </select>
                                        <!-- <span v-if="album.errors.creator"
                                              class="text-danger text-small">{{ album.errors.creator }}
                                        </span> -->
                                    </div>
                                </div>
                        </div>



                        <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label class="form-label">Primary Genre*</label>
                                        <select v-model="album.genre_id"  id="customSelect" class="custom-select block" required>
                                            <option value="" selected>Select a primary genre</option>
                                            <option v-for="primarygenre in album_params.primarygenres" :key="primarygenre.id" :value="primarygenre.id">
                                                {{ primarygenre.name }}
                                            </option>
                                        </select>
                                        <!-- <span v-if="album.errors.primarygenre"
                                          class="text-danger text-small">{{ album.errors.primarygenre }}</span> -->
                                    </div>
                                </div>
                                <div class="form-group  col-md-6">
                                    <div class="controls">
                                        <label class="form-label">Sub Genre</label>
                                        <select v-model="album.sub_genre_id" id="customSelect" class="custom-select block" >
                                            <option value="" selected>Select a subgenre</option>
                                            <option v-for="subgenre in album_params.subgenres" :key="subgenre.id" :value="subgenre.id">
                                                {{ subgenre.name }}
                                            </option>
                                        </select>
                                        <!-- <span v-if="album.errors.subgenre"
                                              class="text-danger text-small">{{ album.errors.subgenre }}</span> -->
                                    </div>
                                </div>
                        </div>

                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button type="submit"  class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1" :disabled="saving">{{  saving ? 'In progress. Please wait...':'Next' }}</button>
                             </div>
                        <!-- </div> -->
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ValidationErrors from "../../Shared/ValidationErrors";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {VueEditor} from "vue2-editor";
// import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';


const FilePond = vueFilePond(
        FilePondPluginFileValidateType,
        FilePondPluginImagePreview,
        // FilePondPluginFilePoster
    );

export default {
    metaInfo: {title: 'Create album'},
    name: 'albumCreate',
    layout: Layout,
    props: ['album_params'],
    components: {
        BreadCrumbs,
        ValidationErrors,
        FilePond,
        Link,
    },
    data() {
        return {
            breadCrumbsDetails: {
            link: "admin.albums.index",
            linkPage: "All albums",
            currentPage: "Create album",
           },
            album: {
                title: null,
                user_id: null,
                description: null,
                sub_genre_id: null,
                genre_id: null,
            },
            saving: false,
            artworkFile: [],
            mp3File: [],
            albumFile: [],
            // myFiles: []

        }
    },
    methods: {
        submit(){
                const formData = new FormData();
                const artworkUploaded = this.$refs.artworkFile.getFiles();

                if (artworkUploaded.length > 0) {
                    artworkUploaded.forEach(file => formData.append('artwork_url', file.file));
                }

                this.$inertia.post(route('admin.albums.store',this.album), formData,{
                    preserveScroll: true,
                    onSuccess: () => {
                        this.saving = false;
                    },
                    onBefore: () => {
                        this.saving = true;
                    },    
                    onError: () => {
                        this.saving = false;
                    }         
                });



        },
        handleFilePondInit(){
        },
        handleFilePondLoad(response){
                // this.form.myFiles = response;
        },


    }

 }

</script>
