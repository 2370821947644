<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
            </div>
        </div>

        <div class="content-body">
            <section class="card" style="background-color: #1E1F22; color:#AFB6B2;">
                <div class="card-body ml-2 rounded-lg" style="background-color: #1E1F22; color:white;">

                    <form @submit.prevent="submit" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-12">
                                <validation-errors></validation-errors>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="form-group col-md-12">
                                <div class=" space-x-16">
                                    <span class="text-lg" style="font-size: 20px; margin-right: 15px;"><input
                                            style="height: 18px; width: 18px; background-color: green;"
                                            @change.prevent="showQuick" :checked="quick" type="radio" id="quick"
                                            name="customize" value="Quick Customization">&nbsp;Quick Customization</span>
                                    <span class="text-lg" style="font-size: 20px; margin-right: 15px;"><input
                                            style="height: 18px; width: 18px; background-color: green;"
                                            @change.prevent="showFull" :checked="!quick" type="radio" id="full"
                                            name="customize" value="Full Customization">&nbsp;Full Customization</span>

                                </div>
                            </div>
                        </div>

                        <div v-if="quick">
                            <div class="row">
                                <div class="col-md-10">
                                    <p>Quick customization enables easy customization of contracts on Tracks. The values in
                                        the input boxes are key
                                        figures applicable to the contract terms . Set Prices For the different Licenses
                                        available.</p>
                                </div>
                            </div>

                            <div class="row  rounded-xl mt-2">
                                <!-- <div class="col-md-1"></div> -->
                                <SellingPreferenceCard :preferenceParams='{
                                    license: "Basic License (MP3)",
                                    priceValue: "5,000",
                                    distributionValue: "5,000",
                                    audioValue: "10,000",
                                    videoValue: "100,000",

                                }' />

                                <SellingPreferenceCard :preferenceParams='{
                                    license: "Professional (MP3 + WAV)",
                                    priceValue: "5,000",
                                    distributionValue: "5,000",
                                    audioValue: "10,000",
                                    videoValue: "100,000",


                                }' />

                                <SellingPreferenceCard :preferenceParams='{
                                    license: "Unlimited License ((MP3 + WAV + Track Stem File)",
                                    priceValue: "5,000",
                                    distributionValue: "5,000",
                                    audioValue: "10,000",
                                    videoValue: "100,000",


                                }' />

                                <SellingPreferenceCard :preferenceParams='{
                                    license: "Exclusive License (MP3 + WAV + Track Stem File)",
                                    priceValue: "5,000",
                                    distributionValue: "Unlimited",
                                    audioValue: "Unlimited",
                                    videoValue: "Unlimited",


                                }' />


                            </div>
                        </div>

                        <!-- FULL CUSTOMIZATION -->
                        <div v-if="!quick">
                            <div class="row">
                                <div class="col-md-12">
                                    <p>Full customization gives the flexibility to manually edit or rewrite the contract
                                        template.
                                        Set Prices for the different licenses available.</p>
                                </div>
                            </div>
                            <div class="row  rounded-xl mt-2">
                                    <!-- <div class="col-md-1"></div> -->
                                    <div class="col-md-12">
                                        <a @click.prevent="actionSeeFullBasic" :style="`background-color: #282933; color:white; border: 2px solid ${fullCustomizationBasic? 'white':'black'};`"
                                            class="btn glow mb-1 mb-sm-0  mr-sm-1">Basic</a>
                                        <a @click.prevent="actionSeeFullProfessional" :style="`background-color: #282933; color:white; border: 2px solid ${fullCustomizationProfessional? 'white':'black'};`"
                                            class="btn glow mb-1 mb-sm-0  mr-sm-1">Professional</a>
                                        
                                        <a @click.prevent="actionSeeFullUnlimited" :style="`background-color: #282933; color:white; border: 2px solid ${fullCustomizationUnlimited ? 'white':'black'};`"
                                            class="btn glow mb-1 mb-sm-0  mr-sm-1">Unlimited</a>
                                           
                                         <a @click.prevent="actionSeeFullExclusive" :style="`background-color: #282933; color:white; border: 2px solid ${fullCustomizationExclusive ? 'white':'black'};`"
                                            class="btn glow mb-1 mb-sm-0  mr-sm-1">Exclusive</a>
                                         <!-- <button style="background-color: #282933; color:#AFB6B2;" type="submit"
                                            class="btn glow mb-1 mb-sm-0 mr-0 mr-sm-1">Professional</button>
                                        <button style="background-color: #282933; color:#AFB6B2;" type="submit"
                                            class="btn glow mb-1 mb-sm-0 mr-0 mr-sm-1">Unlimited</button>
                                        <button style="background-color: #282933; color:#AFB6B2;" type="submit"
                                            class="btn glow mb-1 mb-sm-0 mr-0 mr-sm-1">Exclusive</button> -->
                                    </div>
                                   
                             </div>
                            <div v-if="fullCustomizationProfessional" class="row  rounded-xl mt-2">
                                    
                                <div style="height: auto; background-color: #282933; color: white ; border-radius: 5px; margin-bottom: 3px; width: 100%;"  class="col-md-12 pt-1 pb-1">
                                    <div style="margin-top: 10px;">
                                        <!-- <span style="float: left;">Basic Licence</span> -->
                                        <span >
                                            <label style="color:#AFB6B2;" for="Price">Professional Price</label>
                                            <input style="display: inline; background-color: #1E1F22; color: white; border-radius: 4px;" type="text"
                                                value="5,000"
                                                required>        
                                        </span>
                                    </div>
                                
                                    <div style="margin-top: 10px;">
                                   
                                        <span style="float: left;">
                                           <vue-editor v-model="descriptionTest"></vue-editor>
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div v-if="fullCustomizationUnlimited" class="row  rounded-xl mt-2">
                                    
                                    <div style="height: auto; background-color: #282933; color: white ; border-radius: 5px; margin-bottom: 3px; width: 100%;"  class="col-md-12 pt-1 pb-1">
                                        <div style="margin-top: 10px;">
                                            <!-- <span style="float: left;">Basic Licence</span> -->
                                            <span >
                                                <label style="color:#AFB6B2;" for="Price">Unlimited Price</label>
                                                <input style="display: inline; background-color: #1E1F22; color: white; border-radius: 4px;" type="text"
                                                    value="5,000"
                                                    required>        
                                            </span>
                                        </div>
                                    
                                        <div style="margin-top: 10px;">
                                       
                                            <span style="float: left;">
                                               <vue-editor v-model="descriptionTest"></vue-editor>
                                            </span>
                                        </div>
                                    </div>
    
                            </div>
                            <div v-if="fullCustomizationExclusive" class="row  rounded-xl mt-2">
                                    
                                    <div style="height: auto; background-color: #282933; color: white ; border-radius: 5px; margin-bottom: 3px; width: 100%;"  class="col-md-12 pt-1 pb-1">
                                        <div style="margin-top: 10px;">
                                            <!-- <span style="float: left;">Basic Licence</span> -->
                                            <span >
                                                <label style="color:#AFB6B2;" for="Price">Exc. Price</label>
                                                <input style="display: inline; background-color: #1E1F22; color: white; border-radius: 4px;" type="text"
                                                    value="5,000"
                                                    required>        
                                            </span>
                                        </div>
                                    
                                        <div style="margin-top: 10px;">
                                       
                                            <span style="float: left;">
                                               <vue-editor v-model="descriptionTest"></vue-editor>
                                            </span>
                                        </div>
                                    </div>
    
                            </div>
                                <div v-if="fullCustomizationBasic" class="row  rounded-xl mt-2">
                                    
                                    <div style="height: auto; background-color: #282933; color: white ; border-radius: 5px; margin-bottom: 3px; width: 100%;"  class="col-md-12 pt-1 pb-1">
                                        <div style="margin-top: 10px;">
                                            <!-- <span style="float: left;">Basic Licence</span> -->
                                            <span >
                                                <label style="color:#AFB6B2;" for="Price">Basic Price</label>
                                                <input style="display: inline; background-color: #1E1F22; color: white; border-radius: 4px;" type="text"
                                                    value="5,000"
                                                    required>        
                                            </span>
                                        </div>
                                    
                                        <div style="margin-top: 10px;">
                                       
                                            <span style="float: left;">
                                               <vue-editor v-model="descriptionTest"></vue-editor>
                                            </span>
                                        </div>
                                    </div>
    
                                </div>
                            <!-- </div> -->
                        </div>


                        <div class="row mt-1">
                            <div style="float: right;" class="col-md-9 ">
                                <button type="submit" class="btn btn-danger glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                    :disabled="saving">{{ saving ? 'In progress. Please wait...' : 'Previous' }}</button>
                                <button type="submit" class="btn btn-danger glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                    :disabled="saving">{{ saving ? 'In progress. Please wait...' : 'Publish' }}</button>

                            </div>
                        </div>



                        <!-- <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                            <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                :disabled="saving">{{ saving ? 'In progress. Please wait...' : 'Next' }}</button>
                        </div> -->
                        <!-- </div> -->
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ValidationErrors from "../../Shared/ValidationErrors";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { VueEditor } from "vue2-editor";
// import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';
import SellingPreferenceCard from '../../Shared/SellingPreferenceCard.vue';
// import {VueEditor} from "vue2-editor";


const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    // FilePondPluginFilePoster
);

export default {
    metaInfo: { title: 'Selling Preferences' },
    name: 'albumCreate',
    layout: Layout,
    props: [''],
    components: {
        BreadCrumbs,
        ValidationErrors,
        FilePond,
        Link,
        SellingPreferenceCard,
        VueEditor
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.albums.create",
                linkPage: "Previous",
                currentPage: "Album Selling Preferences",
            },
            fullCustomizationBasic:true,
            fullCustomizationProfessional:false,
            fullCustomizationUnlimited:false,
            fullCustomizationExclusive:false,
            descriptionTest: "This License agreement is made on ORDER_DATE ('Effective Date') by and between CLIENT_NAME ('Licensee') and TRACK_OWNER_FULLNAME p/k/a PRODUCER_ALIAS (The 'Licensor' or 'Producer'). Licensor warrants that it controls the mechanical rights in and to the musical work named BEAT_NAME ('Composition') being sold to Licensee as of and prior to the Effective Date The Licensee and Licensor have agreed to the following terms Master Use",
            album: {
                title: null,
                user_id: null,
                description: null,
                sub_genre_id: null,
                genre_id: null,
            },
            quick: true,
            saving: false,
            artworkFile: [],
            mp3File: [],
            albumFile: [],
            // myFiles: []

        }
    },
    methods: {
        showQuick() {
            this.quick = true;
        },
        showFull() {
            this.quick = false;
        },
        actionSeeFullBasic(){
            this.fullCustomizationBasic=true;
            this.fullCustomizationProfessional=false;
            this.fullCustomizationUnlimited=false;
            this.fullCustomizationExclusive=false;
        },
        actionSeeFullProfessional(){
            this.fullCustomizationBasic=false;
            this.fullCustomizationProfessional=true;
            this.fullCustomizationUnlimited=false;
            this.fullCustomizationExclusive=false;
        },
        actionSeeFullUnlimited(){
            this.fullCustomizationBasic=false;
            this.fullCustomizationProfessional=false;
            this.fullCustomizationUnlimited=true;
            this.fullCustomizationExclusive=false;
        },
        actionSeeFullExclusive(){
            this.fullCustomizationBasic=false;
            this.fullCustomizationProfessional=false;
            this.fullCustomizationUnlimited=false;
            this.fullCustomizationExclusive=true;
        },
        submit() {
            const formData = new FormData();
            const artworkUploaded = this.$refs.artworkFile.getFiles();

            if (artworkUploaded.length > 0) {
                artworkUploaded.forEach(file => formData.append('artwork_url', file.file));
            }

            this.$inertia.post(route('admin.albums.store', this.album), formData, {
                preserveScroll: true,
                onSuccess: () => {
                    this.saving = false;
                },
                onBefore: () => {
                    this.saving = true;
                },
                onError: () => {
                    this.saving = false;
                }
            });



        },
        handleFilePondInit() {
        },
        handleFilePondLoad(response) {
            // this.form.myFiles = response;
        },


    }

}

</script>
