<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Set Default Images</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link href="/">Home</Link>
                            </li>
                            <li class="breadcrumb-item active">
                                Set Default Images
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-body">
                    <form @submit.prevent="update">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <label>Current Album Image:</label><br>
                                <img v-if="imagesData.album[0].value !== null" width="70%" style="height: 250px; border-radius: 20px;" :src="imagesData.album[0].value" alt="">
                                <span v-else><b>No Image</b></span>
                             </div>
                            <div class="col-12 col-sm-6">
                                <label class="form-label">Album Image</label>
                                <file-pond
                                    v-if="true"
                                    name="test"
                                    ref="albumDefaultImage"
                                    label-idle="Drag & drop album default image"
                                    :allow-multiple="false"
                                    accepted-file-types="image/jpeg, image/png, image/gif"
                                    data-allow-reorder="true"
                                    data-max-file-size="3MB"
                                    :files="albumDefaultImage"
                                />                          
                             </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <label>Current Track Image:</label><br>
                                <img v-if="imagesData.track[0].value !== null" width="70%" style="height: 250px; border-radius: 20px;" :src="imagesData.track[0].value" alt="">
                                <span v-else><b>No Image</b></span>
                             </div>
                            <div class="col-12 col-sm-6">
                                <label class="form-label">Track Image</label>
                                <file-pond
                                    v-if="true"
                                    name="test"
                                    ref="trackDefaultImage"
                                    label-idle="Drag & drop track default image"
                                    :allow-multiple="false"
                                    accepted-file-types="image/jpeg, image/png, image/gif"
                                    data-allow-reorder="true"
                                    data-max-file-size="3MB"
                                    :files="trackDefaultImage"
                                />                          
                             </div>
                        </div>

                        <hr>
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <label>Current Playlist Image:</label><br>
                                <img v-if="imagesData.playlist[0].value !== null" width="70%" style="height: 250px; border-radius: 20px;" :src="imagesData.playlist[0].value" alt="">
                                <span v-else><b>No Image</b></span>
                             </div>
                            <div class="col-12 col-sm-6">
                                <label class="form-label">Playlist Image</label>
                                <file-pond
                                    v-if="true"
                                    name="test"
                                    ref="playlistDefaultImage"
                                    label-idle="Drag & drop playlist default image"
                                    :allow-multiple="false"
                                    accepted-file-types="image/jpeg, image/png, image/gif"
                                    data-allow-reorder="true"
                                    data-max-file-size="3MB"
                                    :files="playlistDefaultImage"
                                />                          
                             </div>
                        </div>

                                     
                           
                        <div class="row">
                          <div class="col-6 col-sm-6">
                                    <hr>
                                    <button type="submit"
                                    class="btn btn-primary btn-block glow mb-1 mb-sm-0 mr-0 " :disabled="saving">
                                    Update
                                    <i class="fa fa-spinner fa-spin" v-if="saving" />
                                    </button>
                          </div>
                        </div>
                    


                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import vueFilePond from "vue-filepond"
import BreadCrumbs from '@/Shared/BreadCrumbs'
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);


export default {
    metaInfo: { title: 'Set Site Settings' },
    name: 'SetSiteSettings',
    props: ['default_images'],
    components: {
        BreadCrumbs,
        Link
    },
    layout: Layout,
    data() {
        return {
            reloadData: null,
            imagesData: {},
            trackDefaultImage: [],
            albumDefaultImage: [],
            playlistDefaultImage: [],
            saving: false,
            isEditable: false,
        }
    },
    methods: {
        update() {
                let vm = this;

                const formData = new FormData();

                // Object.keys(vm.imagesData).forEach(image => {
                //     if (vm.imagesData[image]) {
                //         formData.append(image, vm.imagesData[image]);
                //     }
                // });

                // formData.append(image, vm.imagesData[image]);

                if (vm.$refs.albumDefaultImage && vm.$refs.albumDefaultImage.getFile()) {
                    formData.append('albumImage', vm.$refs.albumDefaultImage.getFile().file);
                }

                if (vm.$refs.trackDefaultImage && vm.$refs.trackDefaultImage.getFile()) {
                    formData.append('trackImage', vm.$refs.trackDefaultImage.getFile().file);
                }

                if (vm.$refs.playlistDefaultImage && vm.$refs.playlistDefaultImage.getFile()) {
                    formData.append('playlistImage', vm.$refs.playlistDefaultImage.getFile().file);
                }

                const actualData = {
                    data: vm.imagesData,
                    images: formData,
                };

        

                // console.log(vm.imagesDatad)

                this.$inertia.post(route('admin.settings.updateDefaultImages', actualData), formData , {
                    preserveScroll: true,
                    onError: function (e) {
                        console.error(e);
                        vm.saving = false
                    },
                    onSuccess: function () {
                        vm.saving = false
                    },
                });

              

          

                // this.$inertia.post(route("admin.settings.updateSiteSettings", siteData),siteData,{
                // preserveScroll: true,
                // onError: () => {
                //     this.saving = false;
                // }
                //    });
                this.isEditable = false;

        },
        toggleEditable() {
            this.isEditable = !this.isEditable
        }


    },
    mounted() {
        this.imagesData = this.default_images;
    },

}
</script>
