<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Currency</h4>
                </div>
                <div class="card-body">
                    <vue-good-table
                        :columns="columns"
                        :rows="rows"
                        @on-row-click="handleCurrency"
                        :line-numbers="true"
                        compactMode
                        :search-options="{
                            enabled: true,
                            skipDiacritics: true,
                            initialSortBy: {
                                field: 'date_created',
                                type: 'desc',
                            },
                        }"
                        :pagination-options="{
                            enabled: true,
                            mode: 'pages',
                            position: 'top',
                            perPage: 10,
                        }"
                    >
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'action'">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                >
                                    Update Naira Rate
                                </button>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
        <div>


            <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                Currency Rate
                            </h5>
                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form @submit.prevent="updateRate">
                                <div class="form-group">
                                    <label for="">Name</label>
                                    <input
                                        type="text"
                                        :value="form.name"
                                        class="form-control"
                                        readonly
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="">Currency</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        :value="form.currency"
                                        readonly
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="">Naira Rate</label>
                                    <input
                                        type="number"
                                        v-model="form.naira_rate"
                                        class="form-control"
                                    />
                                </div>

                                    <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-primary"

                                >
                                    Save changes
                                </button>
                            </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Layout from "@/Shared/Layout";
import BreadCrumbs from "@/Shared/BreadCrumbs";
import ActionLinks from "@/Shared/ActionLinks";
import { Inertia } from "@inertiajs/inertia";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { Link } from "@inertiajs/inertia-vue";
import { Router } from "@inertiajs/inertia-vue";
import Modal from "@/Shared/Modal.vue";

export default {
    metaInfo: { title: "Currency" },
    props: ["currency"],
    layout: Layout,
    components: {
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
        Modal,
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.playlists.create",
                linkPage: "Currency",
                currentPage: "Currency",
            },
            rows: this.currency.data,
            columns: [
                { label: "Date Added", field: "dataAdded" },
                { label: "Name", field: "name" },
                { label: "Currency", field: "currency" },
                { label: "Naira Rate", field: "naira_rate" },
                { label: "Flag", field: "flag" },
                { label: "Action", field: "action", sortable: false },
            ],
            form: {},
            id:null
        };
    },

    mounted() {
        this.rows = this.currency.data;
    },

    methods: {
        handleCurrency(payload) {
            this.form = Object.assign({}, payload.row);
            this.id = payload.row.id
        },

        async updateRate() {
            await this.$inertia.put(route('admin.currency.update',this.id),this.form);
            location.reload()
        },
    },
};
</script>
