<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="soundkits-view">
                    <!-- soundkits view media object start -->
                    <div class="row">
                        <div class="col-12 col-sm-7">
                        <div class="media mb-2">
                            <a class="mr-1" href="#">
                             <!-- <img src="/images/defaultsoundimage.png" alt="tracks view avatar" class="tracks-avatar-shadow rounded-circle" height="64" width="64"> -->
                             <img :src="soundkit[0].artwork_url" alt="soundkit view avatar" class="soundkit-avatar-shadow rounded-circle" height="64" width="64">
                            </a>
                            <div class="media-body pt-25">
                            <h4 class="media-heading"><span class="soundkits-view-name">{{ soundkit[0].title }}</span></h4>
                            <span>More info:</span>
                            <span class="soundkits-view-id">{{soundkit[0].description}}</span>
                            </div>
                        </div>
                        </div>
                        <!-- <div class="col-12 col-sm-5 px-0 d-flex justify-content-end align-items-center px-1 mb-2">
                            <Link href="#" class="btn btn-sm btn-primary">Edit</Link>
                        </div> -->
                    </div>
                    <!-- soundkits view media object ends -->
                    <!-- soundkits view card data start -->
                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">
                            <div class="row">
                            <div class="col-12 col-md-8">
                                <table class="table table-borderless">
                                <tbody>
                                    <!-- <tr>
                                        
                                        <td>
                                            <a v-if="isActive" @click="changeVerification(soundkit[0].id)"  class="btn btn-md btn-danger">Unverify</a>
                                            <a v-if="!isActive" @click="changeVerification(soundkit[0].id)"  class="btn btn-md btn-primary">Verify</a>
                                            
                                        </td>
                                    </tr> -->
                                    <tr>
                                    <td>Registered:</td>
                                    <td>{{ soundkit[0].created_at }}</td>
                                    </tr>
                                    <tr>
                                    <td>Last updated:</td>
                                    <td class="soundkits-view-latest-activity">{{ soundkit[0].updated_at }}</td>
                                    </tr>
                                    <tr>
                                    <td>Status:</td>
                                    <td>
                                        <span v-if="isActive" class="badge badge-success soundkits-view-status">Published</span>
                                        <span v-if="!isActive" class="badge badge-danger soundkits-view-status">Draft</span>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        
                            </div>
                        </div>
                        </div>
                    </div>
                    <!-- soundkits view card data ends -->
                    <!-- soundkits view card details start -->
                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">
                        
                                    <h5 class="mb-1"><i class="feather icon-info"></i> Soundkit Details</h5>
                                    <div class="row">
                                    <div class="col-12 col-md-10">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <!-- <tr>
                                            <td> <a @click="destroy(soundkit[0].id)" href="#">delete</a> </td>
                                        </tr> -->
                                        <tr>
                                            <td>Title:</td>
                                            <td>{{soundkit[0].title}}</td>
                                        </tr>
                                        <tr>
                                            <td>Producer:</td>
                                            <td>{{soundkit[0].creator}}</td>
                                        </tr>
                                        <tr>
                                            <td>Genre:</td>
                                            <td>{{soundkit[0].primarygenre}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    </div>
                            
                        </div>
                        </div>
                    </div>
                    <!-- soundkits view card details ends -->

                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">
                        
                                    <h5 class="mb-1"><i class="feather icon-info"></i> Soundkit Files</h5>
                                    <div class="row">
                                    <div class="col-12 col-md-12">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td><a target="_blank" href="#">Soundkit Artwork</a></td>
                                        </tr>
                                        <tr>
                                            <td><a target="_blank" href="#">Soundkit MP3</a></td>
                                        </tr>
                                        <tr>
                                            <td><a target="_blank" :href="soundkit[0].file_url">Soundkit ZIP</a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    </div>
                            
                        </div>
                        </div>
                    </div>


            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-vue';

export default {
    metaInfo: {title: 'Show Soundkit'},
    props: ['soundkit'],
    layout: Layout,
    components: {
        BreadCrumbs,
        Link,
        Inertia
    },
    data() {
        return {
            breadCrumbsDetails: {
            link: "admin.soundkits.index",
            linkPage: "All SoundKits",
            currentPage: "View SoundKit",
           },
           isActive: false
        }
    },
    methods: {
        destroy(id){
            Swal.fire({
            title: 'Are you sure to delete this record?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                    )
                }else if(result.dismiss == 'cancel'){
                    Swal.fire(
                    'Ops!',
                    'You cancelled this action',
                    'info'
                    )
                }else{
                    Swal.fire(
                    'Ops!',
                    'The intented action was not success',
                    'error'
                    )
                }
            })
        }
    },
    mounted(){
        this.isActive = this.soundkit[0].status
    }
 }
</script>
