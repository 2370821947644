var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-header row"},[_c('div',{staticClass:"content-header-left col-md-6 col-12 mb-2"},[_c('BreadCrumbs',{attrs:{"crumbsParams":_vm.breadCrumbsDetails}})],1)]),_vm._v(" "),_c('div',{},[_c('div',{staticClass:"card card-header table-responsive"},[_c('h4',{staticClass:"card-title mb-1"},[_vm._v("Albums")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('div',{staticClass:"controls"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search by title","required":""},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})])])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('table',{staticClass:"table table-bordered table-striped"},[_vm._m(2),_vm._v(" "),_c('tbody',_vm._l((_vm.rows),function(row,index){return _c('tr',{key:row.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(index + 1))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.created_at))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.title))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(row.creator))]),_vm._v(" "),_c('td',[_c('ActionLinks',{attrs:{"actionParams":{
                                            title: 'admin.albums',
                                            id: row.id,
                                            showView: true,
                                            showEdit: false,
                                            showDelete: true
                                            }}})],1)])}),0)]),_vm._v(" "),_c('div',{staticClass:"footer",staticStyle:{"float":"right"}},[_c('Pagination',{attrs:{"links":_vm.linkData}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"heading-elements-toggle"},[_c('i',{staticClass:"fa fa-ellipsis-v font-medium-3"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heading-elements"},[_c('ul',{staticClass:"list-inline mb-0"},[_c('li',[_c('a',{attrs:{"data-action":"expand"}},[_c('i',{staticClass:"feather icon-maximize"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"p-2"},[_c('th',[_vm._v("SN")]),_vm._v(" "),_c('th',[_vm._v("Date")]),_vm._v(" "),_c('th',[_vm._v("Name")]),_vm._v(" "),_c('th',[_vm._v("Producer")]),_vm._v(" "),_c('th',[_vm._v("Action")])])])
}]

export { render, staticRenderFns }