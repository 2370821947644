<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Unread Notifications 
                        <a href="" v-if="rows.length > 0" @click.prevent="markAllAsRead"  class="btn btn-md btn-primary float-right">Mark all as read</a>
                    </h4>

                </div>
            </section>
        </div>

<div class="card p-1 content-body">
<!-- <div class="app-content-overlay"></div> -->
            <div v-if="rows.length > 0">
                <div  v-for="row in rows" :key="row.id" style="color:black">
                    <div :style="`background-color:  ${row.read_at ? '#E5E4E2' : '#8C1111'}; color:  ${row.read_at ? 'black' : 'white'};`"  :class="`alert alert-dismissible fade show`" role="alert">
                    <a style="color:white" @click.prevent="markAsRead(row.id)" href="#">{{ row.title }} &nbsp;&nbsp;&nbsp;<span class="float-right" style="font-size: 12px; color:white">{{ row.created_at }}</span></a>
                    <div v-if="row.read_at">{{ row.body }}</div>
                    </div>
                </div>
                <Pagination :links="links" /> 
            </div>
            <div v-else>No record found</div>
       

</div>
     
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ActionLinks from '@/Shared/ActionLinks';
import Pagination from '@/Shared/Paginate';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';
import { Router } from '@inertiajs/inertia-vue';


export default {
    metaInfo: {title: 'notifications'},
    props: ['notifications'],
    layout: Layout,
    components:{
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
        Pagination,
    },
    data() {
    return {
           breadCrumbsDetails: {
                link: "admin.notifications.index",
                linkPage: "All Notifications",
                currentPage: "Unread Notifications",
           },
           links: {},
           showMarkAllButton: true,
           rows: this.notifications.data,
           columns: [
            {label: 'Date Sent', field: 'created_at'},
            {label: 'Title', field: 'title'},
            {label: 'Body', field: 'body'},
            // { label: 'Status', field: 'status' },
            {label: 'Action', field: 'action'},
            
           ]
        }
    },
    methods: {
         markAsRead(id){
             this.$inertia.post(route('admin.notifications.markAsRead',id));
             this.rows =  this.$inertia.get(route('admin.notifications.unreadNotifications'));          
        },
        markAllAsRead(){
                Swal.fire({
                title: 'Are you sure to mark all as read?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!',
                }).then((result) => {
                if (result.value) {
                   this.$inertia.post(route('admin.notifications.markAllAsRead'));
                    
                    setTimeout(() => {
                    document.location.reload();
                    }, 3000);

                    Swal.fire(
                    'Success',
                    'All marked as read!',
                    'success'
                    )
                    }else if(result.dismiss == 'cancel'){
                        Swal.fire(
                        'Ops!',
                        'You cancelled this action',
                        'info'
                        )
                    }else{
                        Swal.fire(
                        'Ops!',
                        'The intented action was not successful',
                        'error'
                    )
                }
                })
            // }


        }
    },
    mounted() {
             this.rows = this.notifications.data;
             this.showMarkAllButton = this.notifications.data[0].show_mark_all_button;
             this.links = this.notifications.links;//
    },
}
</script>
