<template>
    <div>
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                    <li v-for="link in links" :key="link.label" :tabindex="`${link.url ? '':'-1'}`" :class="`page-item ${link.active ? 'active' :''} ${link.url ? '' :'disabled'}`">
                        <Link style="background-color: #8C1111;" v-if="link.url" v-html="link.label" class="page-link" :href="link.url" preserve-scroll></Link>
                        <span class="page-link" v-else v-html="link.label"></span>
                    </li>
            </ul>
        </nav>
    </div>
</template>


<script>
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';


export default {
    name: "Pagination",
    props:['links'],
    components: {
        Link,
        Inertia,
    },
}
</script>


