<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>
        

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Soundkits</h4>
                </div>
                <div class="card-body">
                    <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :line-numbers="true"
                    compactMode
                    :search-options="{
                    enabled: true,
                    skipDiacritics: true,
                    initialSortBy: {field: 'created_at', type: 'desc'}
                    }"
                    :pagination-options="{
                     enabled: true,
                     mode: 'pages',
                     position: 'top',
                     perPage: 10
                    }"
                    >
                    <template slot="table-row" slot-scope="props">   
                      
                        <span v-if="props.column.field === 'action'">

                            <ActionLinks :actionParams="{
                                title: 'admin.soundkits',
                                id: props.row.id,
                                showView: true,
                                showEdit: false,
                                showDelete: true
                            }">
                            </ActionLinks>   

                        </span>    

                        <!-- <span v-if="props.column.field === 'status'">
                            <span class="dropdown">
                            <p v-if="props.row.status" class="badge badge-success soundkits-view-status">Published</p>
                            <p v-if="!props.row.status" class="badge badge-danger soundkits-view-status">Draft</p>
                            </span>
                        </span> -->

                    </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ActionLinks from '@/Shared/ActionLinks';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';
import { Router } from '@inertiajs/inertia-vue';


export default {
    metaInfo: {title: 'Soundkits'},
    props: ['soundkits'],
    layout: Layout,
    components:{
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
    },
    data() {
    return {
           breadCrumbsDetails: {
            link: "admin.soundkits.index",
            linkPage: "All SoundKits",
            currentPage: "SoundKits",
           },
           rows: this.soundkits.data,
           columns: [
            {label: 'Date Added', field: 'created_at'},
            {label: 'Title', field: 'title'},
            {label: 'Producer', field: 'creator'},
            {label: 'Status', field: 'status'},
            {label: 'Action', field: 'action'},

           ]
        }
    },
    mounted() {
             this.rows = this.soundkits.data;
              // console.log(this.allSoundkits);
    },
    // watch: {
    //           rows: function(){
    //             this.rows = this.soundkits.data;
    //           }
    //         //   props: ['soundkits'],
    // }
   

   
    
   
}
</script>