<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="broadcasts-view">
                    <!-- broadcasts view media object start -->
                    <div class="row">
                        <div class="col-12 col-sm-7">
                        <div class="media mb-2">
                            <a class="mr-1" href="#">
                            <img src="/images/defaultsoundimage.png" alt="broadcasts view avatar" class="broadcasts-avatar-shadow rounded-circle" height="64" width="64">
                            </a>
                            <div class="media-body pt-25">
                            <h4 class="media-heading"><span class="broadcasts-view-name"> Broadcast Details</span></h4>
                            
                            </div>
                        </div>
                        </div>
                        <!-- <div class="col-12 col-sm-5 px-0 d-flex justify-content-end align-items-center px-1 mb-2">
                            <Link href="#" class="btn btn-sm btn-primary">Edit</Link>
                        </div> -->
                    </div>
                    <!-- broadcasts view media object ends -->
                    <!-- broadcasts view card data start -->
                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">
                            <div class="row">
                            <div class="col-12 col-md-8">
                                <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                    <td>Registered:</td>
                                    <td>{{ broadcastData.created_at }}</td>
                                    </tr>
                                    <tr>
                                    <td>Sent By:</td>
                                    <!-- //quick fix in next line -->
                                    <td class="broadcasts-view-latest-activity">{{ broadcastData.admin_fname == 'nil' ? '': broadcastData.admin_fname}} {{ broadcastData.admin_lname == 'nil' ? 'Admin': broadcastData.admin_lname}}</td>
                                    </tr>
                                    <tr>
                                    <td>Status:</td>
                                    <td>
                                        <span v-if="isActive" class="badge badge-success broadcasts-view-status">Sent</span>
                                        <span v-if="!isActive" class="badge badge-danger broadcasts-view-status">Pending</span>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        
                            </div>
                        </div>
                        </div>
                    </div>
                    <!-- broadcasts view card data ends -->
                    <!-- broadcasts view card details start -->
                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">
                        
                                    <h5 class="mb-1"><i class="feather icon-info"></i> Basic Details</h5>
                                    <div class="row">
                                    <div class="col-12 col-md-10">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Title:</td>
                                            <td>{{broadcastData.title}}</td>
                                        </tr>
                                        <tr>
                                            <td>Body:</td>
                                            <td>{{broadcastData.body}}</td>
                                        </tr>
                                        <tr>
                                            <td>Broadcast Group:</td>
                                            <td>{{broadcastData.group == 'Single Account (User or Producer)' ? 'Single Account (User or Producer) to: '+broadcastData.users_list[0].name:broadcastData.group }}</td>
                                        </tr>
                                        
                                        </tbody>
                                    </table>
                                    </div>
                                    </div>
                            
                        </div>
                        </div>
                    </div>
                    <!-- broadcasts view card details ends -->

                    <!-- <div class="card">
                        <div class="card-content">
                        <div class="card-body">
                        
                                    <h5 class="mb-1"><i class="feather icon-info"></i> Recipient(s)</h5>
                                    <div class="row">
                                    <div class="col-12 col-md-12">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr v-for="(broadcast,key) in broadcastData.users_list" :key="broadcast.id">
                                            <td><Link target="_blank" :href="route('admin.users.show', broadcast.id)">{{ key + 1 }}. {{ broadcast.name }}</Link></td>
                                        </tr>
                                       
                                        </tbody>
                                    </table>
                                    </div>
                                    </div>
                            
                        </div>
                        </div>
                    </div> -->


            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-vue';

export default {
    metaInfo: {title: 'Show Broadcast'},
    props: ['broadcast'],
    layout: Layout,
    components: {
        BreadCrumbs,
        Link,
        Inertia
    },
    data() {
        return {
            breadCrumbsDetails: {
            link: "admin.broadcasts.index",
            linkPage: "All Broadcasts",
            currentPage: "View Broadcast",
           },
           broadcastData: {},
           isActive: false

        }
    },
    methods: {
        destroy(id){
            if (confirm("Are you sure you want to Delete")) {
                Inertia.delete(route("admin.broadcasts.destroy", id));
            }
        }
    },
    mounted(){
        this.broadcastData = this.broadcast[0]
        this.isActive = this.broadcast[0].status
    }
 }
</script>
