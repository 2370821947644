<template>
    <div>
        <!-- start page title -->
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <!-- end page title -->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Genre</h4>
                    </div>
                    <div class="card-body">
                        <validation-errors></validation-errors>
                        <form @submit.prevent="updateGenre()">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label for="title" class="form-label">Title</label>
                                    <input class="form-control" type="text" id="title"
                                           placeholder="e.g tungrill beat.com" v-model="genre.name">
                                </div>
                            </div>
                             <div class="row">
                                <div class=" col-12 mb-3">
                                    <label for="title" class="form-label">Description</label>
                                    <textarea class="form-control" name="meta_description" id="meta_description"
                                              cols="30"
                                              rows="2`" v-model="genre.description"></textarea>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label for="file" class="form-label">Genre Files</label>
                                    <input class="form-control" type="file" id="file" @change="handleFileUpload">
                            </div>
                        </div>



                            <div class="row">
                                <div class="col-md-4 col-xs-12">
                                    <button type="submit" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import {VueEditor} from "vue2-editor";
import ValidationErrors from "../../Shared/ValidationErrors";
import Multiselect from 'vue-multiselect'
import BreadCrumbs from '@/Shared/BreadCrumbs';


export default {
    metaInfo: {title: 'Edit Genre'},
    name: 'updateGenre',
    components:{
        BreadCrumbs,
        ValidationErrors
    },
    props: ['genreData'],
    layout: Layout,
    data() {
        return {
            genre: this.$inertia.form({
                name: null,
                description: null,
            }),
            // saving: false,

            breadCrumbsDetails: {
            link: "admin.genres.index",
            linkPage: "All Genre",
            currentPage: "Edit Genre",
           },

        //    this.saving = true;
        }
    },
    methods: {
        async updateGenre() {
            this.saving = true;

                this.$inertia.put(route('admin.genres.update', this.genreData[0].id), {...this.genre}, {
                    preserveScroll: true,
                    onSuccess: () => this.saving = false,
                    onError: () => {
                        this.saving = false;
                    }
                });
                this.saving = false;
        },

        handleFileUpload(event) {
            const file = event.target.files[0];
             this.genre.photo_path = URL.createObjectURL(file);
        }
    },
    mounted() {

        this.genre = this.genreData[0]


    },
}
// export default {
//     metaInfo: {title: 'Edit Genre'},
//     layout: Layout,
//     components: {
//         VueEditor,
//         ValidationErrors,
//         BreadCrumbs
//     },
//     data(){
//         return{
//         genre: {},
//         saving: false,
//         photo_path:null,
//         breadCrumbsDetails: {
//             link: "admin.genres.index",
//             linkPage: "All Genre",
//             currentPage: "Create Genre",
//            },

//         }
//     },
//     methods: {

//         async createGenre() {
//             this.saving = true;
//             let vm = this;

//             try {
//                  const formData = new FormData();



//                 formData.append('name', this.genre['name'])
//                 formData.append('description', this.genre['description'])

//                 // add post key value pair
//                 Object.keys(this.genre).forEach(genre => formData.append(genre, this.genre[genre]));

//                 this.$inertia.post(route('admin.genres.store'), formData, {
//                     preserveScroll: true,
//                     onError: () => vm.saving = false,
//                     onSuccess: () => vm.saving = false,
//                 })

//             } catch (e) {
//                 console.log(e);
//                 this.saving = false
//             }
//         },

//         handleFileUpload(event) {
//             const file = event.target.files[0];
//             console.log(file)
//             this.genre.photo_path = URL.createObjectURL(file);
//         }
//     },


// }
</script>

