<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>
        

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Users</h4>
                </div>
                <div class="card-body">
                    <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :line-numbers="true"
                    compactMode
                    :search-options="{
                    enabled: true,
                    skipDiacritics: true,
                    initialSortBy: {field: 'date_created', type: 'desc'}
                    }"
                    :pagination-options="{
                     enabled: true,
                     mode: 'pages',
                     position: 'top',
                     perPage: 10
                    }"
                    >
                    <template slot="table-row" slot-scope="props">         
                        <span v-if="props.column.field === 'action'">
                            <ActionLinks :actionParams="{
                                title: 'admin.users',
                                id: props.row.id,
                                showView: true,
                                showEdit: true,
                                showDelete: true,
                            }">
                            </ActionLinks> 
                        </span>    
                       
                    </template>
                    </vue-good-table>
                    <!-- <vue-good-table
                    mode="remote"
                    :line-numbers="true"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :isLoading.sync="isLoading"
                    :pagination-options="{
                    enabled: true,
                    }"
                    :rows="rows"
                    :columns="columns"/> -->
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ActionLinks from '@/Shared/ActionLinks';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';




export default {
    metaInfo: {title: 'Users'},
    layout: Layout,
    props: ['users','filters'],
    components:{
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
    },
    data() {
    return {
        breadCrumbsDetails: {
            link: "admin.users.create",
            linkPage: "Create User",
            currentPage: "Users",
        },
        isLoading: false,
        columns: [
                { label: 'Date Added', field: 'created_at' },
                { label: 'Name', field: 'name' },
                { label: 'Email', field: 'email' },
                { label: 'Phone Number', field: 'phone_number' },
                // {label: 'Role', field: 'role'},
                { label: 'Action', field: 'action' },
        ],
        search: null,
        pageType: null,
        rows: [],
        totalRecords: 0,
        serverParams: {
            columnFilters: {
            },
            sort: [
            {
            field: '',
            type: ''
            }
            ],
            page: 1, 
            perPage: 10
        }
        }; 
    },
    mounted() {
             this.rows = this.users.data;
             this.totalRecords = this.users.total;
            //  console.log(this.rows);
    },
    methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

   async loadItems(){
        try {
                let routeName = 'admin.users.index';

                const users44 = await axios.get(route(routeName),
                    {...this.serverParams});
                
               return axios.get(`/users/?searchTerm=${this.users.searchTerm}`).then((response) => {
                    this.rows = response.data.users.data
                    this.users = response.data.users
                    })

                // this.totalRecords = users;
                // this.rows = users.data;
                console.log(this.rows) ;
            } catch (e) {
                console.log('error');
            }
    },

    // load items is what brings back the rows from server
    loadItems2() {
        getFromServer(this.serverParams).then(response => {
         this.totalRecords = response.totalRecords;
         this.rows = response.rows;
        //  this.rows = response.rows;
        //  this.rows = this.$inertia.get(route("admin.users.index"));
        // this.rows = this.users.data;
        // this.totalRecords = this.users.total;
        console.log(this.rows+'testing');
       });
       
    }
  },
  watch: {
        search: function (val) {
            this.allQuizzes =  Inertia.get('/quizzes', {
                search:val,
                // preserveState : true,
                // replace       : true,
                // preserveScroll: true,
            });
            this.pageType = val;
            console.log(this.pageType);

        },
    }
   

   
    
   
}
</script>