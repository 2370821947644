<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>


        <div class="content-body">
            <section class="card">
                <div class="card-header" style="display: flex; justify-content: space-between;">
                        <h4 class="card-title">Plan</h4>
                        <span>
                            <a :href="route('admin.plans.create')" class="btn btn-info">Create Plan</a>
                        </span>
                     </div>
                <div class="card-body">
                    <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :line-numbers="true"
                    compactMode
                    :search-options="{
                    enabled: true,
                    skipDiacritics: true,
                    initialSortBy: {field: 'date_created', type: 'desc'}
                    }"
                    :pagination-options="{
                     enabled: true,
                     mode: 'pages',
                     position: 'top',
                     perPage: 10
                    }"
                    >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'action'">
                            <ActionLinks
                                    :actionParams="{
                                        title: 'admin.plans',
                                        id: props.row.id,
                                        showView:true,
                                        showEdit:true,
                                        showDelete:true
                                    }"
                                >
                            </ActionLinks>

                        </span>
                    </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ActionLinks from '@/Shared/ActionLinks';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';
import { Router } from '@inertiajs/inertia-vue';


export default {
    metaInfo: {title: 'Plans'},
    props: ['plans'],
    layout: Layout,
    components:{
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
    },
    data() {
    return {
           breadCrumbsDetails: {
            link: "admin.plans.create",
            linkPage: "Create Plan",
            currentPage: "Plans",
           },
           rows: this.plans.data,
           columns: [
           {label: 'Date Added', field: 'created_at'},
            {label: 'Name', field: 'name'},
            {label: 'Price', field: 'price'},
            {label: 'Features', field: 'features'},
            {label: 'Action', field: 'action'},

           ]
        }
    },
    mounted() {
             this.rows = this.plans.data;
     },


     methods: {
        destroy(id){
            if (confirm("Are you sure you want to Delete")) {
                Inertia.delete(route("admin.plans.destroy", id));
                this.plans.filter(a => a.id !== id);
            }

        }
    }

}
</script>
