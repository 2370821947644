<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div  class="card-body">
  
                    <form @submit.prevent="submit" enctype="multipart/form-data">

                        <div class="row">
                            <div class="col-12">
                                <validation-errors></validation-errors>    
                            </div>
                        </div>
                        <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Name</label>
                                        <input type="text" v-model="user.name" class="form-control"
                                               placeholder="Name"
                                               required>   
                                    </div>
                                </div>
                               
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Username</label>
                                        <input type="text" v-model="user.user_name" class="form-control"
                                               placeholder="Email"
                                               required>     
                                    </div>
                                </div>
                        </div>
                                
                        <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Email</label>
                                        <input type="email" v-model="user.email" class="form-control"
                                               placeholder="Email"
                                               required>     
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Phone Number</label>
                                        <input type="number" v-model="user.phone_number" class="form-control"
                                               placeholder="Phone"
                                               required> 
                                    </div>
                                </div>
                        </div>

                        <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Gender</label>
                                        <select v-model="user.gender" id="customSelect" class="custom-select block" required>
                                            <option value="" :selected="!isEditing">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female" >Female</option>
                                            <!-- <option v-for="user in country.country_code" :key="creator.id" :value="creator.id">
                                                {{ user.country_code }}
                                            </option> -->
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Select Country</label>
                                        
                                        <select v-model="user.country_code" id="customSelect" class="custom-select block" required>
                                            <option value="" selected>Select a Country Code</option>
                                            <option value="NG" >Nigeria</option> 
                                            <option v-for="country in allCountries" :key="country.code" :value="country.code">
                                                {{ country.name }}
                                            </option>
                                        </select>
                                  
                                    </div>
                                </div>
                        </div>

                            <!-- </div> -->

                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button :disabled="saving" type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1">
                                    Save
                                    <i class="fa fa-spinner spinner" v-if="saving"></i>
                                </button>
                            </div>
                    
                    </form> 
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ValidationErrors from "../../Shared/ValidationErrors";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {VueEditor} from "vue2-editor";
// import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';


const FilePond = vueFilePond(
        FilePondPluginFileValidateType, 
        FilePondPluginImagePreview,
        // FilePondPluginFilePoster
    );

export default {
    metaInfo: {title: 'Create User'},
    name: 'UserCreate',
    props: ['countries','isEditing'],
    layout: Layout,
    components: {
        BreadCrumbs,
        ValidationErrors,
        FilePond,
        Link,
    },
    data() {
        return {
            allCountries: [],
            saving:false,
            breadCrumbsDetails: {
            link: "admin.users.index",
            linkPage: "All Users",
            currentPage: "Create User",
           },
            user: {
                name: null,
                email: null,
                user_name: null,
                phone_number: null,
                gender: null,
                country_code: null,
            },
            // artworkFile: [],
         
        }
    },
    methods: {
        submit(){
                let vm = this;
                vm.saving = true;
                this.$inertia.post(route('admin.users.store',this.user),{
                    preserveScroll: true,
                    onSuccess: function () {
                        vm.saving = false
                    },
                });
                vm.saving = false

        },
    },
    mounted(){
        this.allCountries = this.countries;
    }

 }

</script>
