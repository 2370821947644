<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body"><!-- users view start -->
        <section class="users-view">
          <!-- users view media object start -->
          <div class="row">
            <div class="col-12 col-sm-7">
              <div class="media mb-2">
                <a class="mr-1" href="#">
                  <img src="/images/defaultprofile.png" alt="users view avatar" class="users-avatar-shadow rounded-circle" height="64" width="64">
                </a>
                <div class="media-body pt-25">
                  <h4 class="media-heading"><span class="users-view-name">{{ user[0].name }}</span></h4>
                  <span>Email:</span>
                  <span class="users-view-id">{{user[0].email}}</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-5 px-0 d-flex justify-content-end align-items-center px-1 mb-2">
              <!-- <a href="#" class="btn btn-sm mr-25 border"><i class="feather icon-message-square font-small-3"></i></a> -->
              <!-- <a href="#" class="btn btn-sm mr-25 border">Profile</a> -->
              <Link :href="route('admin.users.edit', user[0].id)" class="btn btn-md btn-primary">Edit</Link>
            </div>
          </div>
          <!-- users view media object ends -->
          <!-- users view card data start -->
          <div class="card">
            <div class="card-content">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-8">
                    <table class="table table-borderless">
                      <tbody>
                        <tr>
                            <!-- <td> -->
                                  <!-- <Link :href="route('admin.users.edit', user[0].id)" class="btn btn-md btn-primary">Edit</Link> -->
                            <!-- </td> -->
                            <!-- <td>
                                  <a v-if="isActive" @click="changeVerification(user[0].id)"  class="btn btn-md btn-danger">Unverify</a>
                                  <a v-if="!isActive" @click="changeVerification(user[0].id)"  class="btn btn-md btn-primary">Verify</a>
                                  
                            </td> -->
                        </tr>
                        <tr>
                          <td>Registered:</td>
                          <td>{{ user[0].created_at }}</td>
                        </tr>
                        <tr>
                          <td>Latest Activity:</td>
                          <td class="users-view-latest-activity">{{ user[0].updated_at }}</td>
                        </tr>
                      
                        <tr>
                          <td>Role:</td>
                          <td class="users-view-role">User</td>
                        </tr>
                        <tr>
                          <td>Status:</td>
                          <td>
                            <span v-if="isActive" class="badge badge-success users-view-status">Verified</span>
                            <span v-if="!isActive" class="badge badge-danger users-view-status">Unverified</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              
                </div>
              </div>
            </div>
          </div>
          <!-- users view card data ends -->
          <!-- users view card details start -->
          <div class="card">
            <div class="card-content">
              <div class="card-body">
              
                        <h5 class="mb-1"><i class="feather icon-info"></i> Personal Info</h5>
                        <div class="row">
                        <div class="col-12 col-md-10">
                        <table class="table table-borderless">
                            <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>{{user[0].name}}</td>
                            </tr>
                            <tr>
                                <td>Username:</td>
                                <td>{{user[0].user_name}}</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{{user[0].email}}</td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td>{{user[0].phone_number}}</td>
                            </tr>
                            <tr>
                                <td>Gender:</td>
                                <td>{{user[0].gender}}</td>
                            </tr>
                            <tr>
                                <td>Country:</td>
                                <td>{{user[0].country_code[0].name}}</td>
                            </tr>
                        
                            </tbody>
                        </table>
                        </div>
                        </div>
                
              </div>
            </div>
          </div>
          <!-- users view card details ends -->

        </section>
        <!-- users view ends -->
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-vue';

export default {
    metaInfo: {title: 'Show user'},
    props: ['user'],
    layout: Layout,
    components: {
        BreadCrumbs,
        Link,
        Inertia
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.users.index",
                linkPage: "All users",
                currentPage: "View User",
           },
           isActive: false
        
        }
    },
    methods: {
        destroy(id){
            if (confirm("Are you sure you want to delete user")) {
                Inertia.delete(route("admin.users.delete", id));
            }
        },
        changeVerification(id){
            if (confirm("Are you sure you want to switch Verification")) {
                Inertia.put(route("admin.users.changeVerification",id),this.user[0]);
                setTimeout(() => {
                document.location.reload();
                }, 3000);
            }
        }
    },
    mounted() {
        this.isActive = this.user[0].status
    }
 }
</script>
