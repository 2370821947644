<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div  class="card-body">
                 
                    <form @submit.prevent="submit" enctype="multipart/form-data">

                        <div class="row">
                            <div class="col-12">
                                <!-- {{ userData }} -->
                                <validation-errors></validation-errors>    
                            </div>
                        </div>
                        <div class="row ">
                                <div class="form-group col-sm-6">
                                    <div class="controls">
                                        <label>Name</label>
                                        <input type="text" v-model="userData.name"  class="form-control"
                                               placeholder="Name"
                                               required>   
                                    </div>
                                </div>
                               
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Username</label>
                                        <input type="text" v-model="userData.user_name" name="user_name" class="form-control"
                                               placeholder="Email"
                                               required>     
                                    </div>
                                </div>
                        </div>
                        <div class="row ">
                                <div class="form-group col-sm-6">     
                              
                                    <div class="controls">
                                        <label>Email</label>
                                        <input type="email" v-model="userData.email" name="email" class="form-control"
                                               placeholder="Email"
                                               required>     
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Phone Number:</label>
                                        <input type="text" v-model="userData.phone_number" name="phone_number" class="form-control"
                                               placeholder="Phone"
                                               required> 
                                    </div>
                                </div>
                        </div>

                        <div class="row ">
                             

                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Gender: {{ userData.gender }}</label>
                                        <select  v-model="userData.gender"  id="customSelect" class="custom-select block"  required>
                                            <option :value="userData.gender" selected>{{userData.gender}}</option>
                                            <option value="male">Male</option>
                                            <option value="female" >Female</option>
                                            <!-- <option v-for="user in country.country_code" :key="creator.id" :value="creator.id">
                                                {{ user.country_code }}
                                            </option> -->
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group col-sm-12 col-lg-6">
                                    <div class="controls">
                                        <label>Country Code: {{ userCode  }} </label>
                                        
                                        <select v-model="userCode"  id="customSelect" class="custom-select block"  required>
                                            <option :value="userData.country_code[0].code" selected>{{ userData.country_code[0].name }}</option>
                                            <option v-for="country in allCountries" :key="country.code" :value="country.code">
                                                {{ country.name }}
                                            </option>
                                            <!-- <option v-for="user in country.country_code" :key="creator.id" :value="creator.id">
                                                {{ user.country_code }}
                                            </option> -->
                                        </select>
                                  
                                    </div>
                                </div>
                        </div>

                      

                            <!-- </div> -->

                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1">Save</button>
                            </div>
                        
                    </form> 
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ValidationErrors from "../../Shared/ValidationErrors";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {VueEditor} from "vue2-editor";
// import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';


const FilePond = vueFilePond(
        FilePondPluginFileValidateType, 
        FilePondPluginImagePreview,
        // FilePondPluginFilePoster
    );

export default {
    metaInfo: {title: 'Edit User'},
    name: 'UserEdit',
    props: ['user','countries'],
    layout: Layout,
    components: {
        BreadCrumbs,
        ValidationErrors,
        FilePond,
        Link,
    },
    data() {
        return {
            allCountries: [],
            userCode: null,
            breadCrumbsDetails: {
            link: "admin.users.index",
            linkPage: "All Users",
            currentPage: "Edit User",
           },
            userData: {}
        }
    },
    methods: {
        submit(){
                delete this.userData.country_code;
                this.userData.country_code = this.userCode;
                console.log(this.userData);
                this.$inertia.put(route('admin.users.update',this.user),this.userData,{    
                 preserveScroll: true,
                });
        },
    },
    mounted(){
        this.userData = this.user[0];
        this.allCountries = this.countries;
        this.userCode = this.userData.country_code[0].code;
    }

 }

</script>
