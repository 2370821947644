<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
            </div>
        </div>


        <div class="content-body">
            <section class="">
                <div class="card-header mb-3">
                    <form @submit.prevent="submit" enctype="multipart/form-data">
                        <!-- <div class="row">
                            <div class="col-12">               
                                <validation-errors>
                                    {{ error }}
                                </validation-errors>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="form-group col-md-4 ">
                                <div class="controls">
                                    <label>Select Track</label>
                                    <Select2 v-model="form.track_id" :options="all_tracks" :settings="{ width: '100%' }" />
                                </div>
                            </div>
                            <div class="form-group col-md-4 ">
                                <div class="controls">
                                    <label>Start Date</label>
                                    <input v-model="form.start_date" type="date" class="form-control"
                                        placeholder="Start Date" required>
                                </div>
                            </div>
                            <div class="form-group col-md-4">
                                <div class="controls">
                                    <label>End Date</label>
                                    <input v-model="form.end_date" type="date" class="form-control" placeholder="End Date"
                                        required>
                                </div>

                                <!-- <div class="controls">
                                        <label></label>
                                        <button type="submit" class="btn btn-block btn-primary glow mb-sm-0 mr-0 mr-sm-1" :disabled="saving">{{  saving ? 'In progress. Please wait...':'Add' }}</button>

                                    </div> -->

                            </div>

                            <div class="form-group float-right col-md-4">
                                <div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
                                    <button type="submit" class="btn btn-primary btn-block glow mb-sm-0 mr-0 mr-sm-1"
                                        :disabled="saving">{{ saving ? 'In progress. Please wait...' : 'Add Featured beat'
                                        }}</button>
                                </div>

                            </div>
                        </div>

                        <!-- <div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
                            <button type="submit" class="btn btn-primary glow mb-sm-0 mr-0 mr-sm-1" :disabled="saving">{{  saving ? 'In progress. Please wait...':'Add Featured Producer' }}</button>

                        </div> -->

                    </form>
                </div>
                <div class="card-header">
                    <h4>Featured/Trending Beats</h4>
                </div>
                <div class="card-body card-header">
                    <vue-good-table :columns="columns" :rows="rows" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: { field: 'date_created', type: 'desc' }
                    }" :pagination-options="{
    enabled: true,
    mode: 'pages',
    position: 'top',
    perPage: 10
}">
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'action'">
                                <span
                                    :class="`btn btn-sm ${props.row.expiration_status === true ? 'btn-danger' : 'btn-success'}`">{{
                                        props.row.expiration_status === true ? 'expired' : 'active' }} </span>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ActionLinks from '@/Shared/ActionLinks';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';
import { Router } from '@inertiajs/inertia-vue';
import ValidationErrors from "../../Shared/ValidationErrors";
import Select2 from 'v-select2-component';




export default {
    metaInfo: { title: 'FeaturedBeats' },
    layout: Layout,
    props: ['featured_tracks', 'all_tracks'],
    components: {
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
        ValidationErrors,
        Select2,
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.tracks.featuredBeats",
                linkPage: "Featured Beats",
                currentPage: "Featured Beats",
            },
            rows: this.featured_tracks.data,
            tracks: this.all_tracks.data,
            saving: false,
            reloadData: null,
            form: this.$inertia.form({
                track_id: null,
                start_date: null,
                end_date: null,
            }),
            columns: [
                // {label: 'Date Added', field: 'created_at'},
                { label: 'Title', field: 'title' },
                { label: 'Start Date', field: 'start_date' },
                { label: 'End Date', field: 'end_date' },
                // {label: 'Added By', field: 'admin'},
                { label: 'Status', field: 'action' },
            ]
        }
    },
    methods: {
        producerHandler(e) {
            console.log($("#customSelect2").val())
        },
        submit() {
            // alert('test')
            console.log(this.form);
            this.$inertia.post(route('admin.tracks.storeFeaturedBeat'), this.form, {
                preserveScroll: true,
                onSuccess: () => {
                    this.saving = false;
                    setTimeout(() => { document.location.reload(); }, 3000)
                },
                onBefore: () => {
                    this.saving = true;
                },
                onError: () => {
                    this.saving = false;


                }
            });

        },
    },
    mounted() {
        this.rows = this.featured_tracks.data;
        this.tracks = this.all_tracks.data;
    },
    watch: {
        reloadData(newValue, oldValue) {
            if (newValue) {
                this.rows = this.$inertia.get(route('admin.tracks.featuredBeats'));
                console.log('testing');
            }
        },
    },





}
</script>