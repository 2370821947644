<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="users-view">
                <!-- users view media object start -->
                <div class="row">
                    <div class="col-12 col-sm-7">
                        <div class="media mb-2">
                            <a class="mr-1" href="#">
                                <img src="/images/img.png"
                                     alt="users view avatar" class="users-avatar-shadow rounded-circle" height="64"
                                     width="64">
                            </a>
                            <div class="media-body pt-25">
                                <h4 class="media-heading"><span class="users-view-name">{{
                                        admin.first_name + ' ' + admin.last_name
                                    }}</span></h4>
                                <span>ID:</span>
                                <span class="users-view-id">{{ admin.id }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 px-0 d-flex justify-content-end align-items-center px-1 mb-2">
                        <!-- <a href="#" class="btn btn-sm mr-25 border">Profile</a> -->
                        <a :href="route('admin.admins.edit', admin.id)"
                           class="btn btn-md btn-primary">Edit
                        </a>
                    </div>
                </div>
                <!-- users view media object ends -->
                <!-- users view card data start -->
             
            
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">
                            <div class="col-12 col-md-12">
                                <h5 class="mb-1"><i class="feather icon-info"></i> Personal Info</h5>

                                <table class="table table-borderless">
                                    <tbody>
                                    <tr>
                                        <td>Name:</td>
                                        <td class="users-view-name"> {{
                                                admin.first_name + ' ' + admin.last_name
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>E-mail:</td>
                                        <td class="users-view-email">{{ admin.email }}</td>
                                    </tr>

                                    <tr>
                                        <td>Date Created:</td>
                                        <td class="users-view-email">{{ formattedDate }}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- users view card details ends -->

            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import BreadCrumbs from '@/Shared/BreadCrumbs'


export default {
    metaInfo: {title: 'Show Admin'},
    name: 'AdminShow',
    layout: Layout,
    props: ['admin'],
    components:{
        BreadCrumbs,
    },
    data: () => ({
        saving: false,
        formattedDate: null,
        breadCrumbsDetails: {
            link: "admin.admins.index",
            linkPage: "All Admins",
            currentPage: "View Admin",
        },
        adminData: {}
    }),
    methods: {
    },
    mounted() {
            this.formattedDate = this.admin.created_at.slice(0,10)
    },
}
</script>
