<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="albums-view">
                    <!-- albums view media object start -->
                    <div class="row">
                        <div class="col-12 col-sm-7">
                        <div class="media mb-2">
                            <a class="mr-1" href="#">
                             <img v-if="album[0].artwork_url.length" :src="album[0].artwork_url" alt="album view avatar" class="album-avatar-shadow rounded-circle" height="64" width="64">
                             <img v-else src="/images/defaultsoundimage.png" alt="tracks view avatar" class="tracks-avatar-shadow rounded-circle" height="64" width="64">
                            </a>
                            <div class="media-body pt-25">
                            <h4 class="media-heading"><span class="albums-view-name">{{ album[0].title }}</span></h4>
                            <span>More info:</span>
                            <span class="albums-view-id">{{album[0].description}}</span>
                            </div>
                        </div>
                        </div>
                        <!-- <div class="col-12 col-sm-5 px-0 d-flex justify-content-end align-items-center px-1 mb-2">
                            <Link href="#" class="btn btn-md btn-primary">Edit</Link>
                        </div> -->
                    </div>
                    <!-- albums view media object ends -->
                    <!-- albums view card data start -->
                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">
                            <div class="row">
                            <div class="col-12 col-md-8">
                                <table class="table table-borderless">
                                <tbody>
                                    <!-- <tr>

                                        <td>
                                            <a v-if="isActive" @click="changeVerification(album[0].id)"  class="btn btn-md btn-danger">Unverify</a>
                                            <a v-if="!isActive" @click="changeVerification(album[0].id)"  class="btn btn-md btn-primary">Verify</a>

                                        </td>
                                    </tr> -->
                                    <tr>
                                    <td>Registered:</td>
                                    <td>{{ album[0].created_at }}</td>
                                    </tr>
                                    <tr>
                                    <td>Last updated:</td>
                                    <td class="albums-view-latest-activity">{{ album[0].updated_at }}</td>
                                    </tr>
                                    <tr>
                                    <td>Status:</td>
                                    <td>
                                        <span v-if="isActive" class="badge badge-success albums-view-status">Published</span>
                                        <span v-if="!isActive" class="badge badge-danger albums-view-status">Draft</span>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>

                            </div>
                        </div>
                        </div>
                    </div>
                    <!-- albums view card data ends -->
                    <!-- albums view card details start -->
                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">

                                    <h5 class="mb-1"><i class="feather icon-info"></i> Album Details</h5>
                                    <div class="row">
                                    <div class="col-12 col-md-10">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Title:</td>
                                            <td>{{album[0].title}}</td>
                                        </tr>
                                        <tr>
                                            <td>Producer:</td>
                                            <td>{{album[0].creator}}</td>
                                        </tr>
                                        <tr>
                                            <td>No of free downloads:</td>
                                            <td>{{album[0].free_download}}</td>
                                        </tr>
                                        <tr>
                                            <td>Genre:</td>
                                            <td>{{album[0].primarygenre}}</td>
                                        </tr>
                                        <tr>
                                            <td>No of Tracks:</td>
                                            <td>{{album[0].tracks}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    </div>

                        </div>
                        </div>
                    </div>
                    <!-- albums view card details ends -->

                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">

                                    <h5 class="mb-1"><i class="feather icon-info"></i> Album Files</h5>
                                    <div class="row">
                                    <div class="col-12 col-md-12">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td><a target="_blank" href="#">Album Artwork</a></td>
                                        </tr>

                                        </tbody>
                                    </table>
                                    </div>
                                    </div>

                        </div>
                        </div>
                    </div>


            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-vue';

export default {
    metaInfo: {title: 'Show Album'},
    props: ['album'],
    layout: Layout,
    components: {
        BreadCrumbs,
        Link,
        Inertia
    },
    data() {
        return {
            breadCrumbsDetails: {
            link: "admin.albums.index",
            linkPage: "All Albums",
            currentPage: "View Album",
           },
           isActive: false
        }
    },
    methods: {
        destroy(id){
            if (confirm("Are you sure you want to Delete")) {
                Inertia.delete(route("admin.albums.destroy", id));
            }
        }
    },
    mounted(){
        this.isActive = this.album[0].status
    }
 }
</script>
