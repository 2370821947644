<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Permissions</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Permissions
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <div class="pull-left">
                        <h4>Manage Permissions for <b>{{ role.name }}</b></h4>
                    </div>
                </div>


                <div class="card-body">
                    <div class="search-container">
                        <div class="row">
                            <div class="col-lg-9 col-md-8 col-sm-6 d-flex align-items-center">
                                <img src="/images/search.svg" alt="">

                            </div>
                        </div>

                    </div>

                    <div class="table-responsive">
                        <form novalidate @submit.prevent="updatePermissions">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Permission Name</th>
                                    <th>
                                        Action
                                    </th>

                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(permission, i) in permissions" :key="permission.id">
                                    <td>{{
                                            permission.name.replace('.', ' ')
                                        }}
                                    </td>
                                    <th scope="row">
                                        <input type="checkbox" :value="permission.name"
                                               :id="permission.id"
                                               :checked="true"
                                               v-model="selectedPermissions"
                                        >
                                    </th>
                                </tr>
                                </tbody>
                            </table>
                            <div class="pull-right col-md-6 col-12 mb-md-0 mb-2">
                                <button type="submit"
                                        class="btn btn-primary float-md-right">
                                    Update Permissions
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';

export default {
    metaInfo: {title: 'Permissions'},
    name: 'PermissionIndex',
    props: ['permissions', 'permissionNames', 'role'],
    layout: Layout,

    data() {
        return {
            permissionsCopy: [],
            selectedPermissions: [],
            allPermissions: [],
        }
    },
    mounted() {
        this.allPermissions = this.permissions;
        this.permissionsCopy = this.permissions.map(permission => permission.name);

        this.selectedPermissions = this.permissionsCopy.filter(permission => this.permissionNames.includes(permission));
        console.log(this.selectedPermissions);
    },
    methods: {
        async updatePermissions() {
            this.saving = true;

            this.$inertia.put(route('admin.roles.permissions.update', this.role.id), {
                permissions: this.selectedPermissions
            }, {
                preserveScroll: true,
                onSuccess: () => {
                    this.saving = false;
                },
                onError: () => {
                    this.saving = false;
                }
            })
        },

        serialNo(props) {
            return this.serverParams.page === 1 ? props.index + 1 : (this.serverParams.perPage *
                    (this.serverParams.page - 1)) +
                props.index + 1
        },
    }
}
</script>
