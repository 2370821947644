<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Set Tungrill Percentage</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link href="/">Home</Link>
                            </li>
                            <li  class="breadcrumb-item active">
                                Set Tungrill Percentage 
                            </li>
                        </ol>
                    </div>
                </div>
              </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-body">
                    <form @submit.prevent="updatePercentage">
                       
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Set Tunegrill Percentage for Albums,Tracks and Soundkits: {{ percentageValue }}%   &nbsp;&nbsp; <a href="#" @click.prevent="toggleEditable(isEditable)" class="btn-sm btn-success">Edit&nbsp;<i class="feather icon-edit-2"></i></a></label>
                                        <input type="number" :readonly="!isEditable" v-model="percentageValue" class="form-control"
                                               placeholder=""
                                               required>
                                            <button v-if="isEditable" type="submit" class="btn btn-primary btn-block glow mb-1 mb-sm-0 mr-0 "
                                            :disabled="saving">
                                             Save
                                            <i class="fa fa-spinner fa-spin" v-if="saving"/>
                                            </button>
                                    </div>
                                </div>
                            </div>                    
                        </div>
                            
                      
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import BreadCrumbs from '@/Shared/BreadCrumbs'
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';


export default {
    metaInfo: {title: 'Set Percentage'},
    name: 'SetTunegrillPercentage',
    props: ['percentage_settings'],
    components: {
        BreadCrumbs,
        Link
    },
    layout: Layout,
    data() {
        return {
            reloadData: null,
            percentageData: {},
            percentageValue: null,
            saving:false,
            isEditable: false,
        }
    },
    methods: {
        updatePercentage() {
                // this.reloadData =  Math.floor(Math.random() * 10) + 1;
                this.percentage_settings[0].value = this.percentageValue;
                 this.$inertia.post(route("admin.settings.updateTunegrillPercentage",this.percentage_settings[0]),this.percentage_settings[0],{
                    preserveScroll: true, 
                    onError: () => {
                        this.saving = false;
                    }        
                });
                this.isEditable = false;
        },
        toggleEditable(){
            this.isEditable = !this.isEditable
        }

        
    },
    mounted() {
        this.percentageData = this.percentage_settings[0];
        this.percentageValue = this.percentage_settings[0].value;
    },
    watch: {
            async reloadData(newVal,oldVal){
                if (newVal) {
                await this.$inertia.get(route('admin.settings.showTunegrillPercentage')); 
                this.percentageValue = this.percentage_settings[0].value;
                console.log('testing');
            }
        }
    }

 
}
</script>
