<template>
    <div>
                <span class="dropdown">
                <Link v-if="actionParams.showView" :href="route(actionParams.title+'.show',actionParams.id)"><i class="feather icon-eye"></i></Link>
                <Link v-if="actionParams.showEdit" :href="route(actionParams.title+'.edit',actionParams.id)"><i class="feather icon-edit"></i></Link>
                <a  v-if="actionParams.showDelete" @click.prevent="destroy(actionParams.id)"><i class="feather icon-trash"></i></a>
                </span>
                
    </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';


export default {
    name: "ActionLinks",
    props: {
        actionParams: Object,
    },
    components: {
        Link,
        Inertia,
    },
    methods: {
        destroy(id){
            // if (confirm("Are you sure you want to Delete")) {  
                Swal.fire({
                title: 'Are you sure to delete this record?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                customClass: 'swal-wide',
                }).then((result) => {
                if (result.value) {
                    // this.reloadData = Math.floor(Math.random() * 10) + 1;
                    this.$inertia.delete(route(this.actionParams.title+".destroy", id));
                    
                    setTimeout(() => {
                    document.location.reload();
                    }, 3000);

                    Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                    )
                }else if(result.dismiss == 'cancel'){
                    Swal.fire(
                    'Ops!',
                    'You cancelled this action',
                    'info'
                    )
                }else{
                    Swal.fire(
                    'Ops!',
                    'The intented action was not successful',
                    'error'
                    )
                }
                })
            // }
        }
    },
   

}
</script>


