<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div  class="card-body">
  
                    <form @submit.prevent="submit" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Title(60 Characters max.)</label>
                                        <input type="text" v-model="album[0].title" class="form-control"
                                               placeholder="Title"
                                               required>
                                        <!-- <div v-if="album.errors.title" class="text-danger text-small">
                                            {{ album.errors.title }}
                                        </div> -->
                                    </div>
                                </div>
                                
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Description</label>
                                        <input type="text" v-model="album[0].description" class="form-control"
                                               placeholder="Description"
                                               required>
                                        <!-- <div v-if="album.errors.description" class="text-danger text-small">
                                            {{ album.errors.description }}
                                        </div> -->
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-12 col-sm-12">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Upload Artwork</label>
                                        <file-pond
                                        v-if="true"
                                        name="test"
                                        ref="artworkImage"
                                        label-idle="Drag & drop artwork image"
                                        accepted-file-types="image/jpeg, image/png, image/gif"
                                        data-allow-reorder="true"
                                        data-max-file-size="2MB"
                                        :files="artworkImage"
                                        v-bind:allow-multiple="true"

                                    />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="controls">
                                        <label>File Url</label>
                                        <input type="text" class="form-control" 
                                               placeholder="File url"
                                               >
                                    </div>
                                </div>
                            </div>

                             <div class="col-12 col-sm-12">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Upload MP3 file only </label>
                                                <file-pond
                                                v-if="true"
                                                name="test"
                                                ref="mp3Image"
                                                label-idle="Drag & drop mp3 file"
                                                accepted-file-types="image/jpeg, image/png, image/gif"
                                                data-allow-reorder="true"
                                                data-max-file-size="2MB"
                                                :files="artworkImage"
                                                v-bind:allow-multiple="true"
                                                />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Upload album( Zip File)</label>
                                                <file-pond
                                                v-if="true"
                                                name="test"
                                                ref="albumImage"
                                                label-idle="Drag & drop album zip file"
                                                accepted-file-types="image/jpeg, image/png, image/gif"
                                                data-allow-reorder="true"
                                                data-max-file-size="2MB"
                                                :files="artworkImage"
                                                v-bind:allow-multiple="true"

                                                />
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-md-12 col-sm-12">
                            </div>

                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1">Update</button>
                            </div>
                        </div>
                    </form> 
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import ValidationErrors from "../../Shared/ValidationErrors";
import BreadCrumbs from '@/Shared/BreadCrumbs';
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {VueEditor} from "vue2-editor";
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { Link } from '@inertiajs/inertia-vue';


const FilePond = vueFilePond(
    FilePondPluginFileValidateType, 
    FilePondPluginImagePreview,
    FilePondPluginFilePoster
    );



export default {
    metaInfo: {title: 'Edit album'},
    name: 'albumEdit',
    layout: Layout,
    props: ['album'],
    components: {
        BreadCrumbs,
        ValidationErrors,
        FilePond,
        Link,
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.albums.index",
                linkPage: "All Albums",
                currentPage: "Edit album",
            },
            artworkImage: [],
            mp3Image: [],
            albumImage: [],
            // myFiles: []

        }
    },
    methods: {
        submit(){
            // console.log(this.album[0].id);
            this.$inertia.put(route('admin.albums.update',this.album[0].id),this.album);
        },
        // submit(){
        //         const formData = new FormData();
        //         const artworkUploaded = this.$refs.artworkImage.getFiles();
        //         const mp3Uploaded = this.$refs.mp3Image.getFiles();
        //         const albumUploaded = this.$refs.albumImage.getFiles();

        //         // formData.append('artworkfile',artworkUploaded);
        //         // formData.append('mp3file',mp3Uploaded);
        //         // formData.append('albumfile',albumUploaded);
                
        //         // if (artworkUploaded.length > 0) {
        //         //     artworkUploaded.forEach(function(file) {
        //         //             // console.log(file.file);
        //         //             this.album.artworkfile.append(file.file);
        //         //     }) 
        //         //     formData.append('artworkfile', this.album.artworkfile);

                    
        //         // }

                
        //         if (artworkUploaded.length > 0) {
        //             artworkUploaded.forEach(file => formData.append('artworkfile', file.file));
        //             // console.log(file.file);
        //         }

        //         if (mp3Uploaded.length > 0) {
        //             mp3Uploaded.forEach(file => formData.append('mp3file', file.file));
        //             // console.log(file.file);
        //         }
        //         if (albumUploaded.length > 0) {
        //             albumUploaded.forEach(file => formData.append('albumfile', file.file));
        //             // console.log(file.file);
        //         }
        //         // console.log(uploadedFiles[0].file);
                
        //         // add post key value pair
        //         // Object.keys(this.album).forEach(album => formData.append(album, this.album[album]));

        //         this.$inertia.post(route('Albums.store',this.album), formData);

        // },
        handleFilePondInit(){
                // if(this.form.image)
                // {
                //     this.myFiles = [{
                //         source: '/' + this.form.image,
                //         options: {
                //             type: 'local',
                //             metadata: {
                //                 poster: '/' + this.form.image
                //             }
                //         }
                //     }];
                // }else{
                //     this.myFiles = [];
                // }
            },
        handleFilePondLoad(response)
            {
                // this.form.myFiles = response;
            },
        

    }

 }

</script>
