<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>


        <div class="content-body">
            <section class="">
                <div class="card-header mb-3">
                    <form @submit.prevent="submit" enctype="multipart/form-data">
                        <!-- <div class="row">
                            <div class="col-12">
                                <validation-errors>
                                    {{ error }}
                                </validation-errors>
                            </div>
                        </div> -->

                        <div class="row">
                                <div class="form-group col-md-4 ">
                                    <div class="controls">
                                        <label>Select Playlist</label>
                                        <select v-model="form.playlist_id" name="playlist_id"    id="customSelect" class="custom-select block" required>
                                            <option value="" disabled selected>Select Playlist</option>
                                            <option v-for="(playlist, key) in playlists" :key="playlist.id" :value="playlist.id">{{playlist.title}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group col-md-4 ">
                                    <div class="controls">
                                        <label>Start Date</label>
                                        <input v-model="form.start_date"  type="date"  class="form-control"
                                               placeholder="Start Date"
                                               required>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="controls">
                                        <label>End Date</label>
                                        <input v-model="form.end_date"  type="date"  class="form-control"
                                               placeholder="End Date"
                                               required>
                                    </div>

                                    <!-- <div class="controls">
                                        <label></label>
                                        <button type="submit" class="btn btn-block btn-primary glow mb-sm-0 mr-0 mr-sm-1" :disabled="saving">{{  saving ? 'In progress. Please wait...':'Add' }}</button>

                                    </div> -->

                                </div>

                                <div class="form-group float-right col-md-4">
                                    <div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
                                        <button type="submit" class="btn btn-primary btn-block glow mb-sm-0 mr-0 mr-sm-1" :disabled="saving">{{  saving ? 'In progress. Please wait...':'Add Featured Playlist' }}</button>
                                    </div>

                                </div>
                        </div>

                        <!-- <div class="col-12 d-flex flex-sm-row flex-column justify-content-end">
                            <button type="submit" class="btn btn-primary glow mb-sm-0 mr-0 mr-sm-1" :disabled="saving">{{  saving ? 'In progress. Please wait...':'Add Featured Producer' }}</button>

                        </div> -->

                    </form>
                </div>
                <div class="card-header">
                    <h4>Featured Playlist</h4>
                </div>
                <div class="card-body card-header">
                    <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :line-numbers="true"
                    compactMode
                    :search-options="{
                    enabled: true,
                    skipDiacritics: true,
                    initialSortBy: {field: 'date_created', type: 'desc'}
                    }"
                    :pagination-options="{
                     enabled: true,
                     mode: 'pages',
                     position: 'top',
                     perPage: 10
                    }"
                    >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'action'">
                             <span :class="`btn btn-sm ${props.row.expiration_status === true ? 'btn-danger':'btn-success'}` ">{{ props.row.expiration_status === true ? 'expired':'active' }} </span>
                        </span>
                    </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ActionLinks from '@/Shared/ActionLinks';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';
import { Router } from '@inertiajs/inertia-vue';
import ValidationErrors from "../../Shared/ValidationErrors";



export default {
    metaInfo: {title: 'FeaturedPlaylist'},
    layout: Layout,
    props: ['featured_playlists','all_playlists'],
    components:{
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
        ValidationErrors,
    },
    data() {
    return {
           breadCrumbsDetails: {
            link: "admin.playlists.featuredPlaylists",
            linkPage: "Featured Playlists",
            currentPage: "Featured Playlists",
           },
           rows: this.featured_playlists.data,
           playlists: this.all_playlists.data,
           saving: false,
           reloadData: null,
           form: this.$inertia.form({
                    playlist_id: null,
                    start_date: null,
                    end_date: null,
            }),
           columns: [
            // {label: 'Date Added', field: 'created_at'},
            {label: 'Title', field: 'title'},
            {label: 'Start Date', field: 'start_date'},
            {label: 'End Date', field: 'end_date'},
            // {label: 'Added By', field: 'admin'},
            {label: 'Status', field: 'action'},
           ]
        }
    },
    methods: {
        submit(){

            this.$inertia.post(route('admin.playlists.storeFeaturedPlaylist'), this.form, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.saving = false;
                        setTimeout(() => { document.location.reload(); }, 3000)
                    },
                    onBefore: () => {
                        this.saving = true;
                    },
                    onError: () => {
                        this.saving = false;


                    }
            });

        },
    },
    mounted() {
             this.rows = this.featured_playlists.data;
             this.playlists = this.all_playlists.data;

    },
    watch: {
        reloadData(newValue, oldValue) {
        if (newValue) {
            this.rows =  this.$inertia.get(route('admin.playlists.featuredPlaylists'));
            console.log('testing');
        }
        },
    },





}
</script>
