var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-header row"},[_c('div',{staticClass:"content-header-left col-md-6 col-12 mb-2"},[_c('BreadCrumbs',{attrs:{"crumbsParams":_vm.breadCrumbsDetails}})],1)]),_vm._v(" "),_c('div',{staticClass:"content-body"},[_c('section',{staticClass:"card"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"compactMode":"","search-options":{
                enabled: true,
                skipDiacritics: true,
                initialSortBy: {field: 'created_at', type: 'desc'}
                },"pagination-options":{
                 enabled: true,
                 mode: 'pages',
                 position: 'top',
                 perPage: 10
                }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('ActionLinks',{attrs:{"actionParams":{
                            title: 'admin.broadcasts',
                            id: props.row.id,
                            showView: true,
                            showEdit: false,
                            showDelete: true
                        }}})],1):_vm._e()]}}])})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("Broadcasts")])])
}]

export { render, staticRenderFns }