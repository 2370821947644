<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="tracks-view">
                                
                                <div class="image-grid"  v-if="playlist[0].numberOfTracks >= 4">
                                    <img v-for="(track,index) in playlist[0].tracks.slice(0,4)" :key="track.id"  v-if="track.file_url" :src="track.file_url.replace('/storage/', '')"  :class="`image-${index + 1}`"  />
                                </div>

                                <div class="media mb-2" v-else-if="playlist[0].numberOfTracks >= 1 && playlist[0].numberOfTracks <= 3 && (playlist[0].tracks.slice(0,1).file_url != '' && playlist[0].tracks.slice(0,1).file_url != null )">      
                                    <img v-for="(track,index) in playlist[0].tracks.slice(0,1)" :key="track.id"  v-if="track.file_url" :src="track.file_url.replace('/storage/', '')" style="width:80px; height: 80px;"  class="playlist-avatar-shadow rounded-circle"  />
                                </div>

                                <div class="media mb-2" v-else-if="playlist[0].producer_photo">
                                    <img :src="playlist[0].producer_photo.replace('/storage/','')" style="width:80px; height: 80px;"  class="playlist-avatar-shadow rounded-circle"  />
                                 </div>

                                <div class="media mb-2" v-else>
                                    <img :src="playlist[0].default_photo[0].replace('/storage','')" style="width:80px; height: 80px;"  class="playlist-avatar-shadow rounded-circle"  />
                                </div>
                                
                                <div class="media-body pt-25">
                                   <h4 class="media-heading"><span class="tracks-view-name">{{ playlist[0].title }}</span></h4>
                                </div>
                            
                    <!-- tracks view media object ends -->
                    <!-- tracks view card data start -->
                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">
                            <div class="row">
                            <div class="col-12 col-md-8">
                                <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Created Date:</td>
                                            <td>{{ playlist[0].created_at }}</td>
                                        </tr>
                                        <tr>
                                            <td>Title:</td>
                                            <td class="users-view-role">{{ playlist[0].title  }}</td>
                                        </tr>

                                        <tr>
                                            <td>Description:</td>
                                            <td class="users-view-role">{{ playlist[0].description  }}</td>
                                        </tr>

                                        <tr>
                                            <td>Number of Tracks:</td>
                                             <td>{{ playlist[0].numberOfTracks }}</td>

                                        </tr>
                                        </tbody>
                                    </table>
                            </div>

                            </div>
                        </div>
                        </div>
                    </div>


                    <!-- tracks view card details ends -->

                    <div class="card">
                        <div class="card-content">
                            <div class="card-body">

                                <h5 class="mb-1"><i class="feather icon-info"></i> List Of Tracks</h5>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                                <table class="table table-borderless">
                                            <tbody>
                                                <tr v-for="(track, key) in playlist[0].tracks" :key="track.id">
                                                    <td>{{ key + 1 }}. {{ track.title }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-vue';

export default {
    metaInfo: {title: 'Show Playlist'},
    props: ['playlist'],
    layout: Layout,
    components: {
        BreadCrumbs,
        Link,
        Inertia
    },
    data() {
        return {
            breadCrumbsDetails: {
            link: "admin.playlists.index",
            linkPage: "All Playlist",
            currentPage: "View Playlist",
           },

        }
    },





}
</script>

<style scoped>

</style>
