<template>
    <!-- Begin page -->
   <div>
    <!-- <TopBar></TopBar> -->
    <!-- <SideBar></SideBar> -->
    <div class="app-content content">
        <div class="content-overlay"></div>
        <div class="content-wrapper">
            <flash-messages></flash-messages>
            <slot/>
        </div>
    </div>
  </div>

</template>

<script>
import FlashMessages from '@/Shared/FlashMessages';
// import TopBar from '@/Shared/TopBar.vue';
// import SideBar from '@/Shared/SideBar.vue';

export default {
    name: "Layout",
    components: {
        FlashMessages,
        // TopBar,
        // SideBar,
    },
}
</script>
