<template>
       <div>
                <h3 class="content-header-title mb-0">{{ crumbsParams.currentPage }}</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><Link href="/">Home</Link>
                            </li>
                            <li class="breadcrumb-item"><Link :href="route(crumbsParams.link)" v-html="crumbsParams.linkPage"></Link>
                            </li>
                            <li  class="breadcrumb-item active" v-html="crumbsParams.currentPage">
                            </li>
                        </ol>
                    </div>
                </div>
          </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue';


export default {
    name: "BreadCrumbs",
    props: {
        crumbsParams: Object,
    },
    components: {
        Link,
    },
    data: () => {
        return {
            show: false
        }
    },
 
}
</script>
