<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Producer</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Update Producer
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-body">
                    <form novalidate @submit.prevent="updateCreator()">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Full Name</label>
                                        <input type="text" v-model="creator.name" class="form-control"
                                               placeholder="Fullname"
                                               required>
<!--                                        <div v-if="creator.errors.name" class="text-danger text-small">-->
<!--                                            {{ creator.errors.name }}-->
<!--                                        </div>-->
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="controls">
                                        <label>E-mail</label>
                                        <input type="email" class="form-control" v-model="creator.email"
                                               placeholder="Email"
                                               required>
<!--                                        <div v-if="creator.errors.email" class="text-danger text-small">-->
<!--                                            {{ creator.errors.email }}-->
<!--                                        </div>-->
                                    </div>
                                </div>

                            </div>

                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Username</label>
                                        <input type="text" v-model="creator.user_name" class="form-control"
                                               placeholder="First name"
                                               required>
<!--                                        <div v-if="creator.errors.user_name" class="text-danger text-small">-->
<!--                                            {{ creator.errors.user_name }}-->
<!--                                        </div>-->
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="controls">
                                        <label class="form-label">Phone Number</label>
                                        <input type="text" v-model="creator.phone_number" class="form-control"
                                               placeholder="Phone Number"
                                               required>
<!--                                        <span v-if="creator.errors.phone_number"-->
<!--                                              class="text-danger text-small">{{ creator.errors.phone_number }}-->
<!--                                        </span>-->
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Gender: {{creator.gender}}</label>
                                        <select v-model="creator.gender" id="customSelect" class="custom-select block" required>
                                            <option :value="creator.gender" selected>{{creator.gender}}</option>
                                            <option value="male">Male</option>
                                            <option value="female" >Female</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">

                                <div class="form-group">
                                    <div class="controls">
                                        <label>Country Code: {{ creatorCode  }} </label>

                                        <select v-model="creatorCode"  id="customSelect" class="custom-select block"  required>
                                            <option :value="creator.country_code[0]?.code" selected>{{ creator.country_code[0]?.name }}</option>
                                            <option v-for="country in allCountries" :key="country.code" :value="country.code">
                                                {{ country.name }}
                                            </option>
<!--                                            <option v-for="user in country.country_code" :key="creator.id" :value="creator.id">-->
<!--                                                {{ user.country_code }}-->
<!--                                            </option>-->
                                        </select>

                                    </div>
                                </div>
                            </div>


                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                        :disabled="saving">
                                             Save
                                    <i class="fa fa-spinner fa-spin" v-if="saving"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'

export default {
    metaInfo: {title: 'Producer'},
    name: 'updateCreator',
    props: ['creatorData','countries'],
    layout: Layout,
    data() {
        return {
            allCountries: [],
            creatorCode: null,
            creator: this.$inertia.form({
                name: null,
                user_name: null,
                email: null,
                phone_number: null,
                gender: null,
                country_code: null,

            }),
            saving: false
        }
    },
    methods: {
        async updateCreator() {
                this.saving = true;
                delete this.creator.country_code;
                this.creator.country_code = this.creatorCode;
                this.$inertia.put(route('admin.creators.update', this.creatorData[0].id), {...this.creator}, {
                    preserveScroll: true,
                    onSuccess: () => this.saving = false,
                    onError: () => {
                        this.saving = false;
                    }
                });
                this.saving = false;
        }
    },
    mounted() {
            this.creator.name = this.creatorData[0]?.name
            this.creator.user_name = this.creatorData[0]?.user_name
            this.creator.email = this.creatorData[0]?.email
            this.creator.phone_number = this.creatorData[0]?.phone_number
            this.creator.gender = this.creatorData[0]?.gender
            this.creator.country_code = this.creatorData[0]?.country_code,
            this.allCountries = this.countries;
            this.creatorCode = this.creatorData[0]?.country_code[0]?.code;
    },
}
</script>
