<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails"/>
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Producers</h4>
                </div>
                <div class="card-body vgt-table custom-card-body">
                    <vue-good-table
                        :line-numbers="true"
                        compactMode
                        :columns="columns" :rows="rows" :search-options="{
                            enabled: true,
                            skipDiacritics: true,
                            initialSortBy: {
                                field: 'date_created',
                                type: 'desc',
                            },
                        }" :pagination-options="{
                            enabled: true,
                            mode: 'pages',
                            position: 'top',
                            perPage: 10,
                         }">
                        <template slot="table-row" slot-scope="props">

                            <span v-if="props.column.label === 'Name'" style="display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                ">
                                {{ props.row.name }}
                                <img v-show="props.row.email_verified_at" src="/images/verified.png" width="30"
                                     height="30"
                                     alt="verified_logo"/>
                            </span>
                            <span v-else-if="props.column.label === 'Verification Status'">
                                <!-- <span  :class="{ 'text-success': props.row.verified == 1,  'text-danger': props.row.verified == 0 }"> -->
                                <span> {{ props.row.verified == 1 ? 'Verified' : 'Unverified' }}<br> </span>
                            </span>
                            <span v-else-if="props.column.field === 'action'">

                                <span class="actions-inline">
                                    <a style="font-size: 12px;"
                                       :href="route('admin.creators.show', props.row.id)"
                                       class="dropdown-item edit"><i class="feather icon-eye"></i>
                                        View</a> |
                                    <a target="_blank" style="font-size: 12px;"
                                       :href="route('admin.creators.loginAsProducer', props.row.id)"
                                       class="dropdown-item edit"><i class="fas fa-sign"></i>
                                            Login as Producer</a>|
                                     <a style="font-size: 12px;"
                                        :href="route('admin.creators.edit', props.row.id)"
                                        class="dropdown-item edit"><i class="feather icon-edit-2"></i>
                                        Edit</a> |
<!--                                        <a @click="updateVerification(props.row,props.row.verified)"-->
<!--                                           class="dropdown-item edit">-->
<!--                                            <i class="feather icon-edit-2"></i>{{-->
<!--                                                props.row.verified == 1 ? 'unverify' : 'verify'-->
<!--                                            }}</a> |-->
                                        <a @click="destroy(props.row.id)" style="font-size: 12px;" href="#"
                                           class="dropdown-item text-danger delete">
                                            <i class="feather icon-trash-2"></i> Delete</a>
                                </span>

                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from "@/Shared/Layout";
import BreadCrumbs from "@/Shared/BreadCrumbs";
import ActionLinks from "@/Shared/ActionLinks";
import {Inertia} from "@inertiajs/inertia";
import "vue-good-table/dist/vue-good-table.css";
import {VueGoodTable} from "vue-good-table";
import {Link} from "@inertiajs/inertia-vue";
import {Router} from "@inertiajs/inertia-vue";

export default {
    metaInfo: {title: "Producer"},
    props: ["creators"],
    layout: Layout,
    components: {
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.creators.create",
                linkPage: "Create Producer",
                currentPage: "Producers",
            },
            rows: this.creators.data,
            columns: [
                {label: 'Name', field: 'name'},
                {label: 'Email', field: 'email'},
                {label: 'Phone', field: 'phone_number'},
                {label: 'Verification Status', field: 'verified'},
                {label: 'Action', field: 'action', sortable: false},
            ],
        };
    },
    mounted() {
        this.rows = this.creators.data;
    },

    methods: {
        destroy(id) {
            // if (confirm("Are you sure you want to Delete")) {
            Swal.fire({
                title: 'Are you sure to delete this record?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                customClass: 'swal-wide',
            }).then((result) => {
                if (result.value) {
                    // this.reloadData = Math.floor(Math.random() * 10) + 1;
                    this.$inertia.delete(route('admin.creators.destroy', id));

                    setTimeout(() => {
                        document.location.reload();
                    }, 3000);

                    Swal.fire(
                        'Deleted!',
                        'Record has been deleted.',
                        'success'
                    )
                } else if (result.dismiss == 'cancel') {
                    Swal.fire(
                        'Ops!',
                        'You cancelled this action',
                        'info'
                    )
                } else {
                    Swal.fire(
                        'Ops!',
                        'The intented action was not successful',
                        'error'
                    )
                }
            })
            // }
        },

        updateVerification(data, type) {
            // if (confirm("Are you sure you want to Delete")) {
            Swal.fire({
                title: 'Are you sure to update verification status of this Producer?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, update it!',
                customClass: 'swal-wide',
            }).then((result) => {
                if (result.value) {
                    // this.reloadData = Math.floor(Math.random() * 10) + 1;
                    if (type == 0) {
                        this.$inertia.post(route('admin.creators.verifyUser', data));
                    } else {
                        this.$inertia.post(route('admin.creators.unVerifyUser', data));
                    }

                    setTimeout(() => {
                        document.location.reload();
                    }, 3000);

                    Swal.fire(
                        'Verification action was successful!',
                        'Action was successful',
                        'success'
                    )
                } else if (result.dismiss == 'cancel') {
                    Swal.fire(
                        'Ops!',
                        'You cancelled this action',
                        'info'
                    )
                } else {
                    Swal.fire(
                        'Ops!',
                        'The intented action was not successful',
                        'error'
                    )
                }
            })
            // }
        }
    },
};
</script>
<style scoped>
.actions-inline {
    display: flex;
    flex-wrap: inherit;
}

.actions-inline a {
    margin-right: 1px;  /* Reduce margin between links to 1px */
    font-size: 12px;    /* Keep the font-size as per your requirement */
}

.actions-inline a:last-child {
    margin-right: 0;    /* Ensure no extra space after the last item */
}
.actions-inline a.text-danger {
    color: red;
}
</style>

