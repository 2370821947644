<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Selling Preferences</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link href="/">Home</Link>
                            </li>
                            <li class="breadcrumb-item active">
                                Selling Preferences 
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="page-account-settings">
                <div class="row">
                    <!-- left menu section -->
                    <div class="col-md-3 mb-2 mb-md-0">
                        <ul class="nav nav-pills flex-column mt-md-0 mt-1">
                            <li class="nav-item">
                                <a class="nav-link d-flex active" id="track-pill" data-toggle="pill"
                                   href="#track-vertical" aria-expanded="true">
                                    <i class="feather icon-music"></i>
                                    Tracks    
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link d-flex" id="album-pill" data-toggle="pill"
                                   href="#album-vertical" aria-expanded="false">
                                    <i class="feather icon-book-open"></i>
                                    Albums
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link d-flex" id="soundkit-pill" data-toggle="pill"
                                   href="#soundkit-vertical" aria-expanded="false">
                                    <i class="fas fa-drum"></i>
                                    Soundkits
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- right content section -->
                    <div class="col-md-9">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane active" id="track-vertical"
                                             aria-labelledby="track-pill" aria-expanded="true">
                                            <form @submit.prevent="updatePreferences">
                                                <h3>
                                                    Tracks |
                                                     <a href="#" @click.prevent="toggleEditable(isEditable)" class="btn-sm btn-success">Edit&nbsp;<i class="feather icon-edit-2"></i></a>
                                               
                                                </h3>
                                                 <hr>
                                                 <div class="row mb-2">
                                                    <div class="col-12 col-sm-12">
                                                        <label>Basic License(&#8358;)</label>
                                                        <input type="number" :readonly="!isEditable"
                                                            v-model="sellingPreferenceData.trackBasic[0].value" class="form-control" placeholder=""
                                                            required>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-12 col-sm-12">
                                                        <label>Professional License(&#8358;)</label>
                                                        <input type="number" :readonly="!isEditable"
                                                            v-model="sellingPreferenceData.trackProf[0].value" class="form-control" placeholder=""
                                                            required>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-12 col-sm-12">
                                                        <label>Unllimited License(&#8358;)</label>
                                                        <input type="number" :readonly="!isEditable"
                                                            v-model="sellingPreferenceData.trackUnlimited[0].value" class="form-control"
                                                            placeholder="" required>
                                                        
                                                    </div>
                                                <!-- <div class="col-12 col-sm-6">
                                                    <label>Exclusive License(&#8358;) <div
                                                            :class="`${sellingPreferenceData.trackExclusive[0].value == 0 ? 'badge badge-sm badge-info' : ''}`">
                                                            {{ sellingPreferenceData.trackExclusive[0].value == 0 ? 'Price not set' : '' }}
                                                        </div></label>
                                                    <input type="number" :readonly="!isEditable"
                                                        v-model="sellingPreferenceData.trackExclusive[0].value" class="form-control"
                                                        placeholder="">
                                                </div> -->
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-12 col-sm-12">
                                                        <div class="form-group">
                                                            <div class="controls">
                                                 
                                                                <button v-if="isEditable" type="submit"
                                                                    class="btn btn-primary btn-block glow mb-1 mb-sm-0 mr-0 " :disabled="saving">
                                                                    Save
                                                                    <i class="fa fa-spinner fa-spin" v-if="saving" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                        <div class="tab-pane fade " id="album-vertical" role="tabpanel"
                                             aria-labelledby="album-pill" aria-expanded="false">

                                            <form @submit.prevent="updatePreferences">
                                                <h3>
                                                    Albums |
                                                     <a href="#" @click.prevent="toggleEditable(isEditable)" class="btn-sm btn-success">Edit&nbsp;<i class="feather icon-edit-2"></i></a>
                                               
                                                </h3>

                                            <div class="row mb-2">
                                                <div class="col-12 col-sm-12">
                                                    <label>Basic License(&#8358;)</label>
                                                    <input type="number" :readonly="!isEditable"
                                                        v-model="sellingPreferenceData.albumBasic[0].value" class="form-control" placeholder=""
                                                        required>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-12 col-sm-12">
                                                    <label>Professional License(&#8358;) </label>
                                                    <input type="number" :readonly="!isEditable"
                                                        v-model="sellingPreferenceData.albumProf[0].value" class="form-control" placeholder=""
                                                        required>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-12 col-sm-12">
                                                    <label>Unllimited License(&#8358;)</label>
                                                    <input type="number" :readonly="!isEditable"
                                                        v-model="sellingPreferenceData.albumUnlimited[0].value" class="form-control"
                                                        placeholder="" required>
                                                </div>
                                                <!-- <div class="col-12 col-sm-6">
                                                    <label>Exclusive License(&#8358;) <div
                                                            :class="`${sellingPreferenceData.albumExclusive[0].value == 0 ? 'badge badge-sm badge-info' : ''}`">
                                                            {{ sellingPreferenceData.albumExclusive[0].value == 0 ? 'Price not set' : '' }}
                                                        </div></label>
                                                    <input type="number" :readonly="!isEditable"
                                                        v-model="sellingPreferenceData.albumExclusive[0].value" class="form-control"
                                                        placeholder="">
                                                </div> -->
                                            </div>
                                            <div class="row mb-2">
                                                    <div class="col-12 col-sm-12">
                                                        <div class="form-group">
                                                            <div class="controls">
                                                        
                                                                <button v-if="isEditable" type="submit"
                                                                    class="btn btn-primary btn-block glow mb-1 mb-sm-0 mr-0 " :disabled="saving">
                                                                    Save
                                                                    <i class="fa fa-spinner fa-spin" v-if="saving" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="tab-pane fade " id="soundkit-vertical" role="tabpanel"
                                             aria-labelledby="soundkit-pill" aria-expanded="false">

                                            <form @submit.prevent="updatePreferences">
                                                <h3>
                                                    Soundkits |
                                                     <a href="#" @click.prevent="toggleEditable(isEditable)" class="btn-sm btn-success">Edit&nbsp;<i class="feather icon-edit-2"></i></a>
                                               
                                                </h3>

                                            <div class="row mb-2">
                                                <div class="col-12 col-sm-12">
                                                    <label>Price(&#8358;)</label>
                                                    <input type="number" :readonly="!isEditable"
                                                        v-model="sellingPreferenceData.soundkitPrice[0].value" class="form-control" placeholder=""
                                                        required>
                                                </div>
                                            </div>
                                          
                                            <div class="row mb-2">
                                                    <div class="col-12 col-sm-12">
                                                        <div class="form-group">
                                                            <div class="controls">

                                                                <button v-if="isEditable" type="submit"
                                                                    class="btn btn-primary btn-block glow mb-1 mb-sm-0 mr-0 " :disabled="saving">
                                                                    Save
                                                                    <i class="fa fa-spinner fa-spin" v-if="saving" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import BreadCrumbs from '@/Shared/BreadCrumbs'
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';


export default {
    metaInfo: { title: 'SellingPreferenceSettings' },
    name: 'SellingPreferenceSettings',
    props: ['selling_preference_settings'],
    components: {
        BreadCrumbs,
        Link
    },
    layout: Layout,
    data() {
        return {
            reloadData: null,
            sellingPreferenceData: {},
            saving: false,
            isEditable: false,
            isTrackSetExclusive: false,
            isAlbumSetExclusive: false,
        }
    },
    methods: {
        updatePreferences() {
                // this.reloadData = Math.floor(Math.random() * 10) + 1;
                // console.log(this.sellingPreferenceData);
                this.$inertia.post(route("admin.settings.updateSellingPreferences", this.sellingPreferenceData), this.sellingPreferenceData, {
                preserveScroll: true,
                onError: () => {
                    this.saving = false;
                }
            });
            this.isEditable = false;
        },
        toggleEditable() {
            this.isEditable = !this.isEditable
        }
    },
    mounted() {
        this.sellingPreferenceData = this.selling_preference_settings;
        // this.isAlbumSetExclusive = this.sellingPreferenceData.albumExclusive[0].value == 0 || this.sellingPreferenceData.albumExclusive[0].value == '' ? 'badge badge-sm badge-info':'';
        // this.isTrackSetExclusive = this.sellingPreferenceData.trackExclusive[0].value == 0 || this.sellingPreferenceData.trackExclusive[0].value == '' ? 'badge badge-sm badge-info':'';
      
    },
    watch: {
        async reloadData(newVal, oldVal) {
            if (newVal) {
                await this.$inertia.get(route('admin.settings.showSellingPreferences'));
            }
        }
    }


}
</script>
