<template>
    <div>
        <!-- start page title -->
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>
        <!-- end page title -->

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Playlist</h4>
                    </div>
                    <div class="card-body">
                        <validation-errors></validation-errors>
                        <form @submit.prevent="updatePlaylist()">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label for="title" class="form-label">Title</label>
                                    <input class="form-control" type="text" id="title"
                                           placeholder="e.g The Human beat playlist" v-model="playlistData.title">
                                </div>
                            </div>

                            <div class="row">
                                <div class=" col-12 mb-3">
                                    <label for="title" class="form-label">Description</label>
                                    <textarea class="form-control" name="meta_description" id="meta_description"
                                              cols="30"
                                              rows="2`" v-model="playlistData.description"></textarea>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label">Playlist Thumbnail Image</label>

                                    <file-pond
                                        v-if="true"
                                        name="test"
                                        ref="playlistImage"
                                        label-idle="Drag & drop playlist image"
                                        accepted-file-types="image/jpeg, image/png, image/gif"
                                        data-allow-reorder="true"
                                        data-max-file-size="2MB"
                                        :files="playlistImage"
                                    />
                                </div>
                            </div>

                            <div class="row">
                                <div class=" col-12 mb-3">
                                    <label class="form-label">Choose Tracks</label>
                                    <multiselect
                                    id="track_id"
                                    v-model="playlistData.selectedTracks"
                                    :options="tracks"
                                    track-by="id"
                                    :close-on-select="false"
                                    :internal-search="true"
                                    label="title"
                                    :multiple="true"
                                    placeholder="Select Tracks"

                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-xs-12">
                                    <button type="submit" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {VueEditor} from "vue2-editor";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import ValidationErrors from "../../Shared/ValidationErrors";
import Multiselect from 'vue-multiselect'
import BreadCrumbs from "@/Shared/BreadCrumbs";



const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,

);

export default {
    metaInfo: {title: 'Playlist'},
    props: ['playlists', 'tracks'],
    layout: Layout,
    components: {
        VueEditor,
        FilePond,
        ValidationErrors,
        Multiselect,
        BreadCrumbs
    },
    data: () => ({
        playlistData: {},
        saving: false,
        playlistImage: [],
        selectedTracks:null,
        allTracksId:[],

            breadCrumbsDetails: {
            link: "admin.playlists.index",
            linkPage: "All Playlist",
            currentPage: "Edit Playlist",
           },



    }),
    methods: {
        async updatePlaylist() {
            this.saving = true;
            let vm = this;

            try {
                const formData = new FormData();
                const uploadedFiles = this.$refs.playlistImage.getFiles();



                const trackId = Object.values(this.playlistData.selectedTracks)

                trackId.forEach(val =>{
                     this.allTracksId.push(val.id)
                })


                if (uploadedFiles.length > 0) {
                    uploadedFiles.forEach(file => formData.append('image', file.file));
                }


                formData.append('title', this.playlistData['title'])
                formData.append('description', this.playlistData['description'])


                for(let i=0; i<this.allTracksId.length; i++){
                    formData.append('selectedTracks[]', this.allTracksId[i])
                 }



                this.$inertia.put(route('admin.playlists.update', this.playlists), this.playlistData, {
                    preserveScroll: true,
                    onError: () => vm.saving = false,
                    onSuccess: () => vm.saving = false,
                })




            } catch (e) {
                console.log(e);
                this.saving = false
            }
        },

    },

    mounted(){
        this.playlistData = this.playlists[0]
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
