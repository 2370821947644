<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="tracks-view">
                    <!-- tracks view media object start -->
                    <div class="row">
                        <div class="col-12 col-sm-7">
                        <div class="media mb-2">
                            <a class="mr-1" href="#">
                            <img :src="genre[0].photo_path" alt="tracks view avatar" class="tracks-avatar-shadow rounded-circle" height="64" width="64">
                            </a>
                            <div class="media-body pt-25">
                            <h4 class="media-heading"><span class="tracks-view-name">{{ genre[0].name }}</span></h4>
                            <!-- <span>More info:</span>
                            <span class="tracks-view-id">{{track[0].description}}</span> -->
                            </div>
                        </div>
                        </div>

                    </div>
                    <!-- tracks view media object ends -->
                    <!-- tracks view card data start -->
                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">
                            <div class="row">
                            <div class="col-12 col-md-8">
                                <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Created Date:</td>
                                            <td>{{ genre[0].created_at }}</td>
                                        </tr>
                                        <tr>
                                            <td>Title:</td>
                                            <td class="users-view-role">{{ genre[0].name  }}</td>
                                        </tr>

                                        <tr>
                                            <td>NUmbers of SubGenres:</td>
                                             <td>{{ genre[0].numberOfGenre }}</td>

                                        </tr>
                                        </tbody>
                                    </table>
                            </div>

                            </div>
                        </div>
                        </div>
                    </div>


                    <!-- tracks view card details ends -->

                    <div class="card">
                        <div class="card-content">
                        <div class="card-body">

                            <h5 class="mb-1"><i class="feather icon-info"></i>List Of SubGenre</h5>
                                    <div class="row">
                                    <div class="col-12 col-md-12">
                                        <table class="table table-borderless">
                                    <tbody>
                                        <tr v-for="(subgenre, key) in genre[0].subGenres" :key="subgenre.id">
                                            <td>{{ key + 1 }}. {{ subgenre.name }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                    </div>
                                    </div>

                        </div>
                        </div>
                    </div>


            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-vue';

export default {
    metaInfo: {title: 'Show Genre'},
    props: ['genre'],
    layout: Layout,
    components: {
        BreadCrumbs,
        Link,
        Inertia
    },
    data() {
        return {
            breadCrumbsDetails: {
            link: "admin.genres.index",
            linkPage: "All Genre",
            currentPage: "View Genre",
           },

        }
    },





}
</script>

<style scoped>

</style>
