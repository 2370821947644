<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div  class="card-body">

                    <form @submit.prevent="submit" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-12">               
                                <validation-errors></validation-errors>
                            </div>
                        </div>

                        <div class="row">
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Title</label>
                                        <input  type="text" v-model="form.title" class="form-control"
                                               placeholder="Title"
                                               required>   
                                    </div>
                                </div>
                               
                                <div class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Broadcast Group</label>
                                        <select v-model="form.group" @change="switchGroup($event)"  id="customSelect" class="custom-select block" required>
                                            <option value="" selected>Select Broadcast Group</option>
                                            <option v-for="(data, key) in broadcastGroup" :value="key">{{data}}</option>
                                        </select>
                                    </div>
                                </div>
                        </div>

                        <div class="row">
                                <div v-if="showUsers" class="form-group col-md-6">
                                    <div class="controls">
                                        <label>Users</label>
                                        <select v-model="form.user_id" name="user_id"    id="customSelect" class="custom-select block" required>
                                            <option value="" selected>Select User</option>
                                            <option v-for="(user, key) in usersData" :key="user.id" :value="user.id">{{user.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div :class="`form-group ${showUsers ? 'col-md-6':'col-md-12'}`">
                                    <div class="controls">
                                        <label>Body</label>
                                        <textarea v-model="form.body" cols="10" rows="10" type="text" class="form-control"
                                               placeholder="Message"
                                               required> 
                                        </textarea>  
                                    </div>
                                </div>
                               
                                
                        </div>

                     

                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1" :disabled="saving">{{  saving ? 'Sending...':'Send' }}</button>
                            </div>
                    
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ValidationErrors from "../../Shared/ValidationErrors";
import {VueEditor} from "vue2-editor";
import { Link } from '@inertiajs/inertia-vue';


export default {
    metaInfo: {title: 'Create Broadcast'},
    name: 'BroadcastCreate',
    layout: Layout,
    props: ['broadcast_params'],
    components: {
        BreadCrumbs,
        ValidationErrors,
        Link,
    },
    data() {
        return {
            broadcastGroup: {},
            usersData: {},
            showUsers: false,
            saving: false,
            form: this.$inertia.form({
                    title: null,
                    body: null,
                    group: null,
                    user_id: null,
            }),
            breadCrumbsDetails: {
            link: "admin.broadcasts.index",
            linkPage: "All Broadcasts",
            currentPage: "Create Broadcast",
           },


        }
    },
    methods: {
        submit(){
            // alert('test')
            this.$inertia.post(route('admin.broadcasts.store'), this.form, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.saving = false;
                    },
                    onBefore: () => {
                        this.saving = true;
                    },    
                    onError: () => {
                        this.saving = false;
                    }        
            });
        },
        switchGroup(e){
            this.showUsers = e.target.value == 1 ? true : false;
        }

    },
    mounted() {
        this.broadcastGroup = this.broadcast_params.group;
        this.usersData = this.broadcast_params.users;
    }

 }

</script>
