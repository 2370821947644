<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
            </div>
        </div>


        <!-- <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Admins</h4>
                </div>
                <div class="card-body">
                    <vue-good-table :columns="columns" :rows="rows" :line-numbers="true" compactMode :search-options="{
                        enabled: true,
                        skipDiacritics: true,
                        initialSortBy: { field: 'created_at', type: 'desc' }
                    }" :pagination-options="{
    enabled: true,
    mode: 'pages',
    position: 'top',
    perPage: 10
}">
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'action'">
                                <ActionLinks :actionParams="{
                                    title: 'admin.admins',
                                    id: props.row.id,
                                }">
                                </ActionLinks>

                            </span>

                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div> -->

        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Admins</h4>
                <a class="heading-elements-toggle"><i class="fa fa-ellipsis-v font-medium-3"></i></a>
                <div class="heading-elements">
                    <ul class="list-inline mb-0">
                        <!-- <li><a data-action="reload"><i class="feather icon-rotate-cw"></i></a></li> -->
                        <li><a data-action="expand"><i class="feather icon-maximize"></i></a></li>
                     
                    </ul>
                </div>
            </div>
            <div class="card-content collapse show">
                <div class="card-body">
                    <p class="card-text">Use <code>.table-striped</code> with <code>.table-bordered</code> to add zebra-striping to any table row within the <code class="highlighter-rouge">&lt;tbody&gt;</code>.</p>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row in rows" :key="row.id">
                                <th scope="row">{{ row.created_at }}</th>
                                <td>{{ row.first_name }} {{ row.last_name }}</td>
                                <td>{{ row.email }}</td>
                                <td>edit</td>
                            </tr>
                           
                        </tbody>
                    </table>

                        <div class="card-footer">
                            <!-- <nav aria-label="...">
                                <ul class="pagination" >
        
                                <li v-for="link in links"  :class="`page-item ${link.active ? 'active': ''}`">
                                    <Link  class="page-link"  :href="link.url">{{ link.label.replace('&laquo;','') }}<span v-if="link.active"  class="sr-only">(current)</span></Link>     
                                </li>
 
                                </ul>
                            </nav> -->
                            <Pagination :links="links" />


                        </div>
                     </div>
                </div>
               
        </div>
        </div>


</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ActionLinks from '@/Shared/ActionLinks';
import Pagination from '@/Shared/Paginate';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';
import { Router } from '@inertiajs/inertia-vue';


export default {
    metaInfo: { title: 'admins' },
    layout: Layout,
    props: ['admins'],
    components: {
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
        Pagination,   
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.admins.create",
                linkPage: "Create Admin",
                currentPage: "Admins",
            },
            rows: this.admins.data,
            links: {},
            columns: [
                { label: 'Date Added', field: 'created_at' },
                { label: 'First Name', field: 'first_name' },
                { label: 'Last Name', field: 'last_name' },
                { label: 'Email', field: 'email' },
                { label: 'Action', field: 'action' },
            ]
        }
    },
    mounted() {
        this.rows = this.admins.data;
        this.links = this.admins.links;
        // console.log(this.alladmins);
    },






}
</script>