<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Account Setting</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Setting
                            </li>
                            <li class="breadcrumb-item active">Account Setting
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="page-account-settings">
                <div class="row">
                    <!-- left menu section -->
                    <div class="col-md-3 mb-2 mb-md-0">
                        <ul class="nav nav-pills flex-column mt-md-0 mt-1">
                            <li class="nav-item">
                                <a class="nav-link d-flex active" id="account-pill-general" data-toggle="pill"
                                   href="#account-vertical-general" aria-expanded="true">
                                    <i class="feather icon-globe"></i>
                                    General
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link d-flex" id="account-pill-password" data-toggle="pill"
                                   href="#account-vertical-password" aria-expanded="false">
                                    <i class="feather icon-lock"></i>
                                    Change Password
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- right content section -->
                    <div class="col-md-9">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body">
                                    <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane active" id="account-vertical-general"
                                             aria-labelledby="account-pill-general" aria-expanded="true">
                                           
                                            <form novalidate="" @submit.prevent="updateProfile">
                                                <div class="media">

                                                    <img v-if="profile.profile_photo" :src="profile.profile_photo"
                                                         class="rounded mr-75" alt="profile image" height="64"
                                                         width="64">
                                                <!-- {{ profile.profile_photo.replace('blob:', '') }} -->
                                                <div class="media-body mt-75">
                                                    <div
                                                        class="col-12 px-0 d-flex flex-sm-row flex-column justify-content-start">
                                                        <label
                                                            class="btn btn-sm btn-primary ml-50 mb-50 mb-sm-0 cursor-pointer"
                                                            for="account-upload">Upload new photo</label>
                                                        <input type="file" id="account-upload" hidden="" @change="onSelectFile">
                                                    </div>
                                                    <p class="text-muted ml-75 mt-50"><small>Allowed JPG, GIF or PNG.
                                                        Max
                                                        size of
                                                        800kB</small></p>
                                                </div>
                                            </div>
                                            </form>
                                            <hr>
                                            <form novalidate="" @submit.prevent="updateProfile">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <div class="controls">
                                                                <label for="account-username">First name</label>
                                                                <input type="text" class="form-control"
                                                                       id="account-username" placeholder="Username"
                                                                       required=""
                                                                       v-model="profile.first_name"
                                                                       data-validation-required-message="This username field is required">
                                                                <div class="help-block"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <div class="controls">
                                                                <label for="account-name">Last name</label>
                                                                <input type="text" class="form-control"
                                                                       v-model="profile.last_name">
                                                                <div class="help-block"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <div class="controls">
                                                                <label for="account-e-mail">E-mail</label>
                                                                <input type="email" class="form-control"
                                                                       id="account-e-mail" placeholder="Email"
                                                                       disabled
                                                                       v-model="profile.email">
                                                                <div class="help-block"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-12 d-flex flex-sm-row flex-column justify-content-end">
                                                        <button type="submit"
                                                                class="btn btn-primary mr-sm-1 mb-1 mb-sm-0">Save
                                                            changes
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="tab-pane fade " id="account-vertical-password" role="tabpanel"
                                             aria-labelledby="account-pill-password" aria-expanded="false">

                                            <form  @submit.prevent="changePassword">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <validation-errors></validation-errors>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <div class="controls">
                                                                <label for="account-old-password">Old Password</label>
                                                                <input :type="oldPasswordField" class="form-control"
                                                                       id="account-old-password" required=""
                                                                       placeholder="Old Password"
                                                                       v-model="oldPassword"
                                                                       data-validation-required-message="This old password field is required">
                                                                       <span @click.prevent="showOldPassword()">
                                                                        <i class="fa" :class="{'fa-tag':oldPasswordField == 'text', 'fa-eye':!showPassword}" id="show-password"
                                                                           >
                                                                        </i>
                                                                       </span>


                                                                <div class="help-block"></div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <div class="controls">
                                                                <label for="account-new-password">New Password</label>
                                                                <input :type="newPasswordField" name="password"
                                                                       id="account-new-password" class="form-control"
                                                                       placeholder="New Password" required=""
                                                                       v-model="newPassword"
                                                                       data-validation-required-message="The password field is required"
                                                                       minlength="6">
                                                                       <span @click.prevent="showNewPassword()">
                                                                        <i class="fa" :class="{'fa-tag':newPasswordField == 'text', 'fa-eye':!showPassword}" id="show-password"
                                                                           >
                                                                        </i>
                                                                       </span>

                                                                <div class="help-block"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <div class="controls">
                                                                <label for="account-retype-new-password">Retype New
                                                                    Password</label>
                                                                <input :type="confirmPasswordField" name="con-password"
                                                                       class="form-control" required=""
                                                                       id="account-retype-new-password"
                                                                       data-validation-match-match="password"
                                                                       placeholder="New Password"
                                                                       v-model="confirmPassword"
                                                                       data-validation-required-message="The Confirm password field is required"
                                                                       minlength="6">
                                                                       <span @click.prevent="showConfirmPassword()">
                                                                        <i class="fa" :class="{'fa-tag':confirmPasswordField == 'text', 'fa-eye':!showPassword}" id="show-password"
                                                                           >
                                                                        </i>
                                                                       </span>

                                                                <div class="help-block"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="col-12 d-flex flex-sm-row flex-column justify-content-end">
                                                        <button type="submit"
                                                                class="btn btn-primary mr-sm-1 mb-1 mb-sm-0">Save
                                                            changes
                                                        </button>
                                                        <button type="reset" class="btn btn-light">Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import ValidationErrors from "../../Shared/ValidationErrors";

export default {
    metaInfo: {title: 'Create Admin'},
    name: 'AdminCreate',
    props: ['adminData'],
    components: {
        ValidationErrors
    },
    layout: Layout,
    data: () => ({
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
        saving: false,
        profile: {},
        showPassword:false,
        oldPasswordField:'password',
        newPasswordField:'password',
        confirmPasswordField:'password',
        profile_photo:null

    }),
    methods: {
        async changePassword() {
            this.saving = true;
            let vm = this;
            try {
                this.$inertia.post(route('admin.account.change-password'), {
                    password: this.oldPassword,
                    new_password: this.newPassword,
                    new_password_confirmation: this.confirmPassword,
                }, {
                    preserveScroll: true,
                    onError: () => vm.saving = false,
                    onSuccess: () => vm.saving = false,
                });
            } catch (e) {
                console.log(e);
                this.saving = false
            }
        },

        async updateProfile() {
            this.saving = true;
            let vm = this;

            const formData = new FormData();
   

        
            let updatedData = {
                    first_name: this.profile.first_name,
                    last_name: this.profile.last_name,
                    phone_number: this.profile.phone_number,
                    profile_photo_url: this.profile.profile_photo,
                    profile_photo: this.profile.profile_body,
                }


            Object.keys(updatedData).forEach(profile => formData.append(profile, updatedData[profile]));

            console.log(vm.profile.profile_photo,);

            try {
                this.$inertia.post(route('admin.account.update'), formData, {
                    preserveScroll: true,
                    onError: () => vm.saving = false,
                    onSuccess() {
                        location.reload();
                    },
                });

            } catch (e) {
                console.log(e);
                this.saving = false
            }
        },

        showOldPassword(){
            this.oldPasswordField = this.oldPasswordField === 'password' ? 'text' : 'password'
        },

        showNewPassword(){
            this.newPasswordField = this.newPasswordField === 'password' ? 'text' : 'password'
        },

        showConfirmPassword(){
            this.confirmPasswordField = this.confirmPasswordField === 'password' ? 'text' : 'password'
        },

        onSelectFile(event){
            this.profile.profile_body = event.target.files[0]
            this.profile.profile_photo = URL.createObjectURL(event.target.files[0]);
            
        }


    },


    mounted() {
        this.profile = this.adminData;
    },
}
</script>

<style>

.fa-eye{
    position: absolute;
    top:45%;
    right: 4%;
    cursor: pointer;
    color: lightgray;

}

</style>
