var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-header row"},[_c('div',{staticClass:"content-header-left col-md-6 col-12 mb-2"},[_c('BreadCrumbs',{attrs:{"crumbsParams":_vm.breadCrumbsDetails}})],1)]),_vm._v(" "),_c('div',{staticClass:"content-body"},[_c('section',{staticClass:"card"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body vgt-table custom-card-body"},[_c('vue-good-table',{attrs:{"line-numbers":true,"compactMode":"","columns":_vm.columns,"rows":_vm.rows,"search-options":{
                            enabled: true,
                            skipDiacritics: true,
                            initialSortBy: {
                                field: 'date_created',
                                type: 'desc',
                            },
                        },"pagination-options":{
                            enabled: true,
                            mode: 'pages',
                            position: 'top',
                            perPage: 10,
                         }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.label === 'Name')?_c('span',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_vm._v("\n                                "+_vm._s(props.row.name)+"\n                                "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(props.row.email_verified_at),expression:"props.row.email_verified_at"}],attrs:{"src":"/images/verified.png","width":"30","height":"30","alt":"verified_logo"}})]):(props.column.label === 'Verification Status')?_c('span',[_c('span',[_vm._v(" "+_vm._s(props.row.verified == 1 ? 'Verified' : 'Unverified')),_c('br')])]):(props.column.field === 'action')?_c('span',[_c('span',{staticClass:"actions-inline"},[_c('a',{staticClass:"dropdown-item edit",staticStyle:{"font-size":"12px"},attrs:{"href":_vm.route('admin.creators.show', props.row.id)}},[_c('i',{staticClass:"feather icon-eye"}),_vm._v("\n                                        View")]),_vm._v(" |\n                                    "),_c('a',{staticClass:"dropdown-item edit",staticStyle:{"font-size":"12px"},attrs:{"target":"_blank","href":_vm.route('admin.creators.loginAsProducer', props.row.id)}},[_c('i',{staticClass:"fas fa-sign"}),_vm._v("\n                                            Login as Producer")]),_vm._v("|\n                                     "),_c('a',{staticClass:"dropdown-item edit",staticStyle:{"font-size":"12px"},attrs:{"href":_vm.route('admin.creators.edit', props.row.id)}},[_c('i',{staticClass:"feather icon-edit-2"}),_vm._v("\n                                        Edit")]),_vm._v(" |\n"),_vm._v(" "),_c('a',{staticClass:"dropdown-item text-danger delete",staticStyle:{"font-size":"12px"},attrs:{"href":"#"},on:{"click":function($event){return _vm.destroy(props.row.id)}}},[_c('i',{staticClass:"feather icon-trash-2"}),_vm._v(" Delete")])])]):_vm._e()]}}])})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("Producers")])])
}]

export { render, staticRenderFns }