<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="card card-style">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 col-xl-6 col-sm-12 col-6">
                                <div
                                    style="
                                        display: flex;
                                        justify-items: baseline;
                                        align-items: center;
                                        width: 300px;
                                        padding-bottom: 6px;
                                    "
                                >
                                    <i
                                        class="fa fa-money customize-icon font-large-1 mr-1"
                                    ></i>
                                    <h1
                                        style="
                                            font-size: 20px;
                                            font-weight: bold;
                                        "
                                    >
                                        Wallet Balance
                                    </h1>
                                </div>

                                <h3>{{ wallet_balance }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="card card-style">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 col-xl-6 col-sm-12 col-6">
                                <div
                                    style="
                                        display: flex;
                                        justify-items: baseline;
                                        align-items: center;
                                        width: 300px;
                                        padding-bottom: 6px;
                                    "
                                >
                                    <i
                                        class="icon icon-bar-chart customize-icon font-large-1 mr-1"
                                    ></i>
                                    <h1
                                        style="
                                            font-size: 20px;
                                            font-weight: bold;
                                        "
                                    >
                                        Total Transactions
                                    </h1>
                                </div>

                                <h3>
                                    {{ total_transaction }}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Transactions</h4>
                </div>
                <div class="card-body">
                    <vue-good-table
                        :columns="columns"
                        :rows="rows"
                        :line-numbers="true"
                        compactMode
                        :search-options="{
                            enabled: true,
                            skipDiacritics: true,
                            initialSortBy: {
                                field: 'date_created',
                                type: 'desc',
                            },
                        }"
                        :pagination-options="{
                            enabled: true,
                            mode: 'pages',
                            position: 'top',
                            perPage: 10,
                        }"
                    >
                        <template slot="table-row" slot-scope="props">
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from "@/Shared/Layout";
import BreadCrumbs from "@/Shared/BreadCrumbs";
import ActionLinks from "@/Shared/ActionLinks";
import { Inertia } from "@inertiajs/inertia";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { Link } from "@inertiajs/inertia-vue";
import { Router } from "@inertiajs/inertia-vue";

export default {
    metaInfo: { title: "Transactions" },
    props: ["transactions", "total_transaction", 'wallet_balance'],
    layout: Layout,
    components: {
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.transactions.index",
                linkPage: "All Transactions",
                currentPage: "Transactions",
            },
            rows: this.transactions.data,
            columns: [
                { label: "Date Created", field: "created_at" },
                { label: "Sender", field: "seller" },
                { label: "Amount", field: "amount" },
                { label: "Payment Mode", field: "payment_method" },
                { label: "Payment Type", field: "service_type" },
            ],
        };
    },
    mounted() {
        this.rows = this.transactions.data;
    },
};
</script>

<style scoped>
.card-style {
    background: #404e67;
    color: white;
    border-radius: 25px;
}
</style>
