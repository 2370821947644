<template>
    <div>
        <div style="height: auto; background-color: #282933; color: white ; border-radius: 5px; margin-bottom: 3px; width: 100%;"  class="col-md-12 pt-1 pb-1">
                                <div style="margin-top: 10px;">
                                    <span style="float: left;">{{preferenceParams.license}}</span>
                                    <span style="float: right;">
                                        <label style="color:#AFB6B2;" for="Price">Price</label>
                                        <input style="display: inline; background-color: #1E1F22; color: white; border-radius: 4px;" type="text"
                                               :value="preferenceParams.priceValue"
                                               required>        
                                    </span>
                                </div>
                                <div style="height: auto; width: 100%; margin-left: 2px; border-radius: 8px; background-color: #5C5C5E;" class="row pt-2 mt-3 pb-1">
                                        <div class="col-md-4 " style="display: inline;">
                                            <label style="color:#AFB6B2;" for="Distribution copies">Distribution copies</label>
                                            <input style="display: inline; width: 45%; background-color: #1E1F22; color: white; border-radius: 4px;" type="text" 
                                            :value="preferenceParams.distributionValue"
                                               required>
                                        </div>
                                        <div class="col-md-4 " style="display: inline;">
                                            <label style="color:#AFB6B2;" for="Audio stream">Audio stream</label>
                                            <input style="display: inline; background-color: #1E1F22; color: white; border-radius: 4px; " type="text" 
                                            :value="preferenceParams.audioValue"
                                               required>
                                        </div>
                                        <div class="col-md-4 " style="display: inline;">
                                            <label style="color:#AFB6B2;" for="Video stream">Video stream</label>
                                            <input style="display: inline; background-color: #1E1F22; color: white; border-radius: 4px;" type="text" 
                                            :value="preferenceParams.videoValue"
                                               required>
                                        </div>
                          
                                       
                                </div>
                            </div>
    </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue';


export default {
    name: "SellingPreferenceCard",
    props: {
        preferenceParams: Object,
    },
    components: {
        Link,
    },
    data: () => {
        return {
            show: false
        }
    },

}
</script>
