<template>
    <div>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 class="mb-sm-0 font-size-18">Dashboard</h4>
                </div>
            </div>
        </div>
        <br><br>
        <!-- end page title -->

        <!--  dashboard statistics  -->
        <div class="row grouped-multiple-statistics-card">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                                <Link :href="route('admin.creators.index')">
                                    <div
                                        class="d-flex align-items-start mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                            <span class="card-icon danger d-flex justify-content-center mr-3">
                                                <i class="icon p-1 icon-users customize-icon font-large-2 p-1"></i>
                                            </span>
                                        <div class="stats-amount mr-3">
                                            <h3 class="heading-text text-bold-600">
                                                {{ dashboard_params.producers }}
                                            </h3>
                                            <p class="sub-heading">Producers</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                                <Link :href="route('admin.users.index')">
                                <div class="d-flex align-items-start border-right-blue-grey border-right-lighten-5">
                                        <span class="card-icon success d-flex justify-content-center mr-3">
                                            <i class="icon p-1 icon-users customize-icon font-large-2 p-1"></i>
                                        </span>
                                    <div class="stats-amount mr-3">
                                        <h3 class="heading-text text-bold-600">
                                            {{ dashboard_params.users }}
                                        </h3>
                                        <p class="sub-heading">All Users</p>
                                    </div>
                                </div>
                               </Link>
                            </div>

                            <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                              <Link :href="route('admin.tracks.index')">
                                <div class="d-flex align-items-start">
                                        <span class="card-icon warning d-flex justify-content-center mr-3">
                                            <i class="fa p-1 fa-music customize-icon font-large-2 p-1"></i>
                                        </span>
                                    <div class="stats-amount mr-3">
                                        <h3 class="heading-text text-bold-600">
                                           {{  dashboard_params.tracks }}
                                        </h3>
                                        <p class="sub-heading">Tracks</p>
                                        `
                                    </div>
                                </div>
                              </Link>
                            </div>

                            <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                                <Link :href="route('admin.albums.index')">
                                    <div class="d-flex align-items-start">
                                            <span class="card-icon warning d-flex justify-content-center mr-3">
                                                <i class="icon p-1 icon-book-open customize-icon font-large-2 p-1"></i>
                                            </span>
                                        <div class="stats-amount mr-3">
                                            <h3 class="heading-text text-bold-600">
                                            {{  dashboard_params.albums }}
                                            </h3>
                                            <p class="sub-heading">Albums</p>
                                            `
                                        </div>
                                    </div>
                               </Link>
                            </div>

                            <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                              <Link :href="route('admin.soundkits.index')">
                                        <div class="d-flex align-items-start">
                                                <span class="card-icon warning d-flex justify-content-center mr-3">
                                                    <i class="fa p-1 fa-drum customize-icon font-large-2 p-1"></i>
                                                </span>
                                            <div class="stats-amount mr-3">
                                                <h3 class="heading-text text-bold-600">
                                                {{  dashboard_params.soundkits }}
                                                </h3>
                                                <p class="sub-heading">Soundkits</p>
                                                `
                                            </div>
                                        </div>
                                </Link>
                            </div>

                            <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                                <Link :href="route('admin.users.index')">
                                    <div class="d-flex align-items-start">
                                            <span class="card-icon success d-flex justify-content-center mr-3">
                                                <i class="fa p-1 fa-users customize-icon font-large-2 p-1"></i>
                                                <!-- <i class="fa-light fa-users"></i> -->
                                            </span>
                                        <div class="stats-amount mr-3">
                                            <h3 class="heading-text text-bold-600">
                                            {{  dashboard_params.subscribed_users }}
                                            </h3>
                                            <p class="sub-heading">Subscribed Users</p>
                                            `
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                                <div
                                    class="d-flex align-items-start mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                        <span class="card-icon primary d-flex justify-content-center mr-3">
                                            <i class="icon p-1 icon-bar-chart customize-icon font-large-2 p-1"></i>
                                        </span>
                                    <div class="stats-amount mr-3">
                                        <h3 class="heading-text text-bold-600">
                                           {{ dashboard_params.total_revenue }}
                                        </h3>
                                        <p class="sub-heading">Total Revenue</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                                <div
                                    class="d-flex align-items-start mb-sm-1 mb-xl-0 border-right-blue-grey border-right-lighten-5">
                                        <span class="card-icon primary d-flex justify-content-center mr-3">
                                           <i class="fa p-1 fa-list customize-icon font-large-2 p-1"></i>
                                        </span>
                                    <div class="stats-amount mr-3">
                                        <h3 class="heading-text text-bold-600">
                                           {{ dashboard_params.playlists }}
                                        </h3>
                                        <p class="sub-heading">Playlists</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--  end dashboard statistics  -->

    </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import { Link } from '@inertiajs/inertia-vue'
// import BarChart from "../ChartTemplate/BarChart";

export default {
    metaInfo: {title: 'Dashboard'},
    name: 'Dashboard',
    layout: Layout,
    components: {
        Link
    },
    props: [ 'dashboard_params' ],

    data: () => ({
    }),

}
</script>
