<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
            </div>
        </div>

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Playlists</h4>
                </div>
                <div class="card-body">
                    <vue-good-table
                        :columns="columns"
                        :rows="rows"
                        :line-numbers="true"
                        compactMode
                        :search-options="{
                            enabled: true,
                            skipDiacritics: true,
                            initialSortBy: {
                                field: 'date_created',
                                type: 'desc',
                            },
                        }"
                        :pagination-options="{
                            enabled: true,
                            mode: 'pages',
                            position: 'top',
                            perPage: 10,
                        }"
                    >
                        <template slot="table-row" slot-scope="props">


                            <span v-if="props.column.field === 'action'">
                                <ActionLinks
                                    :actionParams="{
                                        title: 'admin.playlists',
                                        id: props.row.id,
                                        showView:true,
                                        showEdit:true,
                                        showDelete:true
                                    }"
                                >
                            </ActionLinks>
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from "@/Shared/Layout";
import BreadCrumbs from "@/Shared/BreadCrumbs";
import ActionLinks from "@/Shared/ActionLinks";
import { Inertia } from "@inertiajs/inertia";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { Link } from "@inertiajs/inertia-vue";
import { Router } from "@inertiajs/inertia-vue";

export default {
    metaInfo: { title: "Playlist" },
    props: ["playlists"],
    layout: Layout,
    components: {
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.playlists.create",
                linkPage: "Create Playlist",
                currentPage: "Playlists",
            },
            rows: this.playlists.data,
            columns: [
            {label: 'Date Added', field: 'dataAdded'},
            {label: 'Name', field: 'title'},
            {label: 'Owner', field: 'creator'},
            {label: 'Action', field: 'action', sortable: false},
        ],
        };
    },
    mounted() {
        this.rows = this.playlists.data;
    },

    methods: {
        destroy(id) {
            if (confirm("Are you sure you want to Delete")) {
                Inertia.delete(route("admin.playlists.destroy", id));
                this.playlists.filter((a) => a.id !== id);
            }
        },
    },
};
</script>
