<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails"/>
            </div>
        </div>

        <div class="content-body">
            <section class="tracks-view">
                <!-- tracks view media object start -->
                <div class="row">
                    <div class="col-12 col-sm-7">
                        <div class="media mb-2">
                            <a class="mr-1" href="#">
                                <img v-if="track[0].artwork_url" :src="track[0].artwork_url" alt="track view avatar"
                                     class="track-avatar-shadow rounded-circle" height="64" width="64">
                            </a>
                            <div class="media-body pt-25">
                                <h4 class="media-heading"><span class="tracks-view-name">{{ track[0].title }}</span>
                                </h4>
                                <span>More info:</span>
                                <span class="tracks-view-id">{{ track[0].description }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-sm-5 px-0 d-flex justify-content-end align-items-center px-1 mb-2">
                    <Link href="#" class="btn btn-md btn-primary">Edit</Link>
                    </div> -->
                </div>
                <!-- tracks view media object ends -->
                <!-- tracks view card data start -->
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <!-- <tr>

                                            <td>
                                                <a v-if="isActive" @click="changeVerification(track[0].id)"  class="btn btn-md btn-danger">Unverify</a>
                                                <a v-if="!isActive" @click="changeVerification(track[0].id)"  class="btn btn-md btn-primary">Verify</a>

                                            </td>
                                        </tr> -->
                                        <tr>
                                            <td>Registered:</td>
                                            <td>{{ track[0].created_at }}</td>
                                        </tr>
                                        <tr>
                                            <td>Last updated:</td>
                                            <td class="tracks-view-latest-activity">{{ track[0].updated_at }}</td>
                                        </tr>
                                        <tr>
                                            <td>Status:</td>
                                            <td>
                                                <span v-if="isActive" class="badge badge-success tracks-view-status">Published</span>
                                                <span v-if="!isActive" class="badge badge-danger tracks-view-status">Draft</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- tracks view card data ends -->
                <!-- tracks view card details start -->
                <div class="card">
                    <div class="card-content">
                        <div class="card-body">

                            <h5 class="mb-1"><i class="feather icon-info"></i> Track Details</h5>
                            <div class="row">
                                <div class="col-12 col-md-10">
                                    <table class="table table-borderless">
                                        <tbody>
                                        <tr>
                                            <td>Title:</td>
                                            <td>{{ track[0].title }}</td>
                                        </tr>
                                        <tr>
                                            <td>Producer:</td>
                                            <td>{{ track[0].user }}</td>
                                        </tr>
                                        <tr>
                                            <td>Beats per minutes:</td>
                                            <td>{{ track[0].beats_per_minute }}</td>
                                        </tr>
                                        <tr>
                                            <td>Genre:</td>
                                            <td>{{ track[0].primarygenre }}</td>
                                        </tr>
                                        <tr>
                                            <td>Subgenre:</td>
                                            <td>{{ track[0].subgenre }}</td>
                                        </tr>
                                        <tr>
                                            <td>Album:</td>
                                            <td>{{ track[0].album }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- tracks view card details ends -->

                <div class="card">
                    <div class="card-content">
                        <div class="card-body">

                            <h5 class="mb-1"><i class="feather icon-info"></i> Track Files</h5>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <table class="table table-borderless">
                                        <tbody>

                                        <tr v-for="media in track[0].media_files" :key="media.id">
                                            <a target="_blank" :href="media.file_url">Track
                                                {{ (media.format === 1 ? 'MP3' : (media.format === 2 ? 'WAV' : 'ZIP')) }} </a>
                                            &nbsp;&nbsp;
                                            <button class="btn btn-sm btn-primary"
                                                    @click="openFileUrlInNewTab(media.file_url)">View File
                                            </button>
                                        </tr>

                                        <br><br>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import {Inertia} from '@inertiajs/inertia';
import {Link} from '@inertiajs/inertia-vue';
import axios from 'axios';

export default {
    metaInfo: {title: 'Show track'},
    props: ['track'],
    layout: Layout,
    components: {
        BreadCrumbs,
        Link,
        Inertia
    },
    data() {
        return {
            breadCrumbsDetails: {
                link: "admin.tracks.index",
                linkPage: "All tracks",
                currentPage: "View track",
            },
            isActive: false
        }
    },
    methods: {
        destroy(id) {
            if (confirm("Are you sure you want to Delete")) {
                Inertia.delete(route("admin.tracks.destroy", id));
            }
        },
        openFileUrlInNewTab(fileUrl) {
            window.open(fileUrl, '_blank');
        },
    },
    mounted() {
        this.isActive = this.track[0].status
    }
}
</script>
