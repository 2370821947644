<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Set Site Settings</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <Link href="/">Home</Link>
                            </li>
                            <li class="breadcrumb-item active">
                                Set Site Settings
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-body">
                    <form @submit.prevent="update">

                        <div class="row">
                            <div class="col-12 col-sm-6">
                                &nbsp;&nbsp; <a href="#" @click.prevent="toggleEditable(isEditable)" class="btn-sm btn-success">Edit&nbsp;<i class="feather icon-edit-2"></i></a>
                                <hr>
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Site Logo Url</label>
                                        <input type="text" :readonly="!isEditable" v-model="siteLogo"
                                           class="form-control" placeholder="" required>
                                        <hr>

                                        <label>Title</label>
                                        <input type="text" :readonly="!isEditable" v-model="siteTitle"
                                            class="form-control" placeholder="" required>

                                        <hr>

                                        <label>Description</label>
                                        <input type="text" :readonly="!isEditable" v-model="siteDescription"
                                            class="form-control" placeholder="" required>

                                        <hr>

                                        <label>Footer text</label>
                                        <input type="text" :readonly="!isEditable" v-model="siteFooterText"
                                            class="form-control" placeholder="" required>


                                    </div>
                                    <div class="controls">
                             
                                    <hr>

                                        <button v-if="isEditable" type="submit"
                                            class="btn btn-primary btn-block glow mb-1 mb-sm-0 mr-0 " :disabled="saving">
                                            Save
                                            <i class="fa fa-spinner fa-spin" v-if="saving" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import BreadCrumbs from '@/Shared/BreadCrumbs'
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';


export default {
    metaInfo: { title: 'Set Site Settings' },
    name: 'SetSiteSettings',
    props: ['site_settings'],
    components: {
        BreadCrumbs,
        Link
    },
    layout: Layout,
    data() {
        return {
            reloadData: null,
            siteData: {},
            siteLogo:null,
            siteTitle:null,
            siteDescription:null,
            siteFooterText:null,
            saving: false,
            isEditable: false,
        }
    },
    methods: {
        update() {
                // this.reloadData = Math.floor(Math.random() * 10) + 1;
                const siteData = {
                    logo: this.siteLogo,
                    title: this.siteTitle,
                    description: this.siteDescription,
                    footer: this.siteFooterText
                };
                this.$inertia.post(route("admin.settings.updateSiteSettings", siteData),siteData,{
                preserveScroll: true,
                onError: () => {
                    this.saving = false;
                }
            });
            this.isEditable = false;

        },
        toggleEditable() {
            this.isEditable = !this.isEditable
        }


    },
    mounted() {
        this.siteData = this.site_settings;
        this.siteLogo = this.site_settings.siteLogo[0].value;
        this.siteTitle = this.site_settings.title[0].value;
        this.siteDescription = this.site_settings.description[0].value;
        this.siteFooterText = this.site_settings.footerText[0].value;
    },
    watch: {
        async reloadData(newVal, oldVal) {
            if (newVal) {
                await this.$inertia.get(route('admin.settings.showSiteSettings'));
                // this.siteData = this.site_settings[0].value;
                console.log('testing');
            }
        }
    }


}
</script>
