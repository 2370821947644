<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                 <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
              </div>
        </div>
        

        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Account Change Requests</h4>
                </div>
                <div class="card-body">
                    <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :line-numbers="true"
                    compactMode
                    :search-options="{
                    enabled: true,
                    skipDiacritics: true,
                    initialSortBy: {field: 'created_at', type: 'desc'}
                    }"
                    :pagination-options="{
                     enabled: true,
                     mode: 'pages',
                     position: 'top',
                     perPage: 10
                    }"
                    >
                    <!-- <template slot="table-row" slot-scope="props">         
                        <span v-if="props.column.field === 'action'">
                             <Link class="btn btn-sm btn-success" @click.prevent="acceptRequest(props.row)" href="">Accept </Link>
                             <Link class="btn btn-sm btn-danger" @click.prevent="rejectRequest(props.row)" href="">Reject</Link>
                        </span>    
                    </template> -->
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
import BreadCrumbs from '@/Shared/BreadCrumbs';
import ActionLinks from '@/Shared/ActionLinks';
import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import { Link } from '@inertiajs/inertia-vue';
import { Router } from '@inertiajs/inertia-vue';


export default {
    metaInfo: {title: 'Requests'},
    layout: Layout,
    props: ['requests'],
    components:{
        ActionLinks,
        BreadCrumbs,
        VueGoodTable,
        Link,
    },
    data() {
    return {
           reloadData: null,
           breadCrumbsDetails: {
            link: "admin.users.index",
            linkPage: "All Users",
            currentPage: "Account Change Requests",
           },
           rows: {},
           columns: [
            {label: 'Date', field: 'created_at'},
            {label: 'Name', field: 'name'},
            {label: 'Email', field: 'email'},
            {label: 'Phone', field: 'phone_number'},
            // {label: 'Action', field: 'action'},
           ]
        }
    },
    methods: {
        acceptRequest(id){
            Swal.fire({
                title: 'Are you sure to accept this change request?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, accept it!',
                customClass: 'swal-wide',
                }).then((result) => {
                if (result.value) {
                    this.reloadData = Math.floor(Math.random() * 10) + 1;
                    this.$inertia.post(route('admin.users.acceptAccountChangeRequest',id),id);                 
                    Swal.fire(
                    'Accepted!',
                    'Change Requested has been accepted.',
                    'success'
                    )
                }else if(result.dismiss == 'cancel'){
                    Swal.fire(
                    'Ops!',
                    'You cancelled this action',
                    'info'
                    )
                }else{
                    Swal.fire(
                    'Ops!',
                    'The intented action was not successful',
                    'error'
                    )
                }
            })
        },
        rejectRequest(id){
            Swal.fire({
                title: 'Are you sure to reject this change request?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, reject it!',
                customClass: 'swal-wide',
                }).then((result) => {
                if (result.value) {
                    this.reloadData = Math.floor(Math.random() * 10) + 1;
                    this.$inertia.post(route('admin.users.rejectAccountChangeRequest',id),id);   
                    Swal.fire(
                    'Rejected!',
                    'Change Requested has been rejected.',
                    'success'
                    )
                }else if(result.dismiss == 'cancel'){
                    Swal.fire(
                    'Ops!',
                    'You cancelled this action',
                    'info'
                    )
                }else{
                    Swal.fire(
                    'Ops!',
                    'The intented action was not successful',
                    'error'
                    )
                }
                })
        },
    },
    watch: {
        reloadData(newValue, oldValue) {
        if (newValue) {
            this.rows =  this.$inertia.get(route('admin.users.getAccountChangeRequests')); 
            console.log('testing');
        }
        },
    },
    mounted() {
             this.rows = this.requests.data;
    },
   
   

   
    
   
}
</script>