<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <BreadCrumbs :crumbsParams="breadCrumbsDetails" />
            </div>
        </div>

        <div class="content-body">
            <section class="card">

                <div class="card-body">
                    <form novalidate @submit.prevent="saveCreator()">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Full Name</label>
                                        <input type="text" v-model="creator.name" class="form-control"
                                               placeholder="Fullname"
                                               required>
                                        <div v-if="creator.errors.name" class="text-danger text-small">
                                            {{ creator.errors.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="controls">
                                        <label>E-mail</label>
                                        <input type="email" class="form-control" v-model="creator.email"
                                               placeholder="Email"
                                               required>
                                        <div v-if="creator.errors.email" class="text-danger text-small">
                                            {{ creator.errors.email }}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Username</label>
                                        <input type="text" v-model="creator.user_name" class="form-control"
                                               placeholder="First name"
                                               required>
                                        <div v-if="creator.errors.user_name" class="text-danger text-small">
                                            {{ creator.errors.user_name }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="controls">
                                        <label class="form-label">Phone Number</label>
                                        <input type="number" v-model="creator.phone_number" class="form-control"
                                               placeholder="Phone Number"
                                               required>
                                        <span v-if="creator.errors.phone_number"
                                              class="text-danger text-small">{{ creator.errors.phone_number }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <div class="controls">
                                        <label>Gender</label>
                                        <select v-model="creator.gender" id="customSelect" class="custom-select block" required>
                                            <option value="" selected>Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female" >Female</option>
                                            <!-- <option v-for="user in country.country_code" :key="creator.id" :value="creator.id">
                                                {{ user.country_code }}
                                            </option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">

                                <div class="form-group">
                                    <div class="controls">
                                        <label>Select Country</label>
                                        
                                        <select v-model="creator.country_code" id="customSelect" class="custom-select block" required>
                                            <option value="" selected>Select a Country Code</option>
                                            <option value="NG" >Nigeria</option> 
                                            <option v-for="country in allCountries" :key="country.code" :value="country.code">
                                                {{ country.name }}
                                            </option>
                                        </select>
                                  
                                    </div>
                                </div>


                            </div>





                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-1">
                                <button type="submit" class="btn btn-primary glow mb-1 mb-sm-0 mr-0 mr-sm-1"
                                        :disabled="saving">
                                             Save
                                    <i class="fa fa-spinner fa-spin" v-if="saving"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import BreadCrumbs from "@/Shared/BreadCrumbs";

export default {
    metaInfo: {title: 'Producer'},
    name: 'AddCreator',
    components:{
        BreadCrumbs
    },
    props: ['creatorData','countries'],
    layout: Layout,
    data() {
        return {
            saving:false,
            allCountries : [],
            breadCrumbsDetails: {
                link: "admin.creators.index",
                linkPage: "All Producers",
                currentPage: "Create Producer",
            },
            creator: this.$inertia.form({
                name: null,
                user_name: null,
                email: null,
                phone_number: null,
                gender: null,
                country_code: null,

            }),
            saving: false
        }
    },
    methods: {
        async saveCreator() {
                let vm = this;
                vm.saving = true;
                this.creator.post(route('admin.creators.store'), this.creator, {
                    preserveScroll: true,
                    onSuccess: () => this.saving = false,
                    onError: () => {
                        vm.saving = false;
                    }
                });


            this.saving = false;
        }
    },
    mounted(){
        this.allCountries = this.countries;
    }

}
</script>
