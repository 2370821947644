// require('./bootstrap');

import Vue from 'vue'
import VueMeta from 'vue-meta'
import {InertiaProgress} from '@inertiajs/progress'
import VueSweetalert2 from 'vue-sweetalert2';
import VueGoodTablePlugin from 'vue-good-table';
import vSelect from 'vue-select'
import {Vuelidate} from 'vuelidate';
import DatePicker from 'vue2-datepicker'
import JsonCSV from 'vue-json-csv';


import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-select/dist/vue-select.css';
import 'vue-good-table/dist/vue-good-table.css'
import 'vue2-datepicker/index.css';
import {createInertiaApp} from "@inertiajs/inertia-vue";
import { Link } from '@inertiajs/inertia-vue';
import { Inertia } from '@inertiajs/inertia';
// import { useForm } from '@inertiajs/inertia-vue2';



import Echo from "laravel-echo";

Vue.mixin({methods: {route: window.route}})
Vue.use(VueMeta)
Vue.use(VueSweetalert2);
Vue.use(DatePicker);
Vue.use(Vuelidate);
Vue.use(Link);
// Vue.use(useForm);
Vue.use(Inertia);
Vue.use(VueGoodTablePlugin);
Vue.component('downloadCsv', JsonCSV);
vSelect.props.components.default = () => ({
    Deselect: {
        render: createElement => createElement('span', 'x'),
    },
    // OpenIndicator: {
    //     render: createElement => createElement('span', '🔽'),
    // },
});

Vue.component('v-select', vSelect)

window.axios = require('axios');

InertiaProgress.init()


createInertiaApp({
    resolve: name => require(`./Pages/${name}`),
    setup({el, app, props}) {
        new Vue({
            metaInfo: {
                titleTemplate: title => (title ? `${title} | TuneGrill` : 'TuneGrill'),
            },
            render: h => h(app, props),
        }).$mount(el)
    },
});
