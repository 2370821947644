<template>
    <div>
        <!-- start page title -->
        <div class="content-header row">
            <div class="content-header-left col-md-6 col-12 mb-2">
                <h3 class="content-header-title mb-0">Subscription Logs</h3>
                <div class="row breadcrumbs-top">
                    <div class="breadcrumb-wrapper col-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Subscription Logs
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- end page title -->


        <div class="content-body">
            <section class="card">
                <div class="card-header">
                    <h4>Subscription Logs</h4>
                </div>
                <div class="card-body">
                    <vue-good-table
                    :columns="columns"
                    :rows="rows"
                    :line-numbers="true"
                    compactMode
                    :search-options="{
                    enabled: true,
                    skipDiacritics: true,
                    initialSortBy: {field: 'created_at', type: 'desc'}
                    }"
                    :pagination-options="{
                     enabled: true,
                     mode: 'pages',
                     position: 'top',
                     perPage: 10
                    }"
                    >
                    <template slot="table-row" slot-scope="props">

                        <!-- <span v-if="props.column.field === 'plan'">
                            <p v-if="props.row.plan">{{ props.row.plan }} Month</p>
                        </span> -->

                    </template>
                    </vue-good-table>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Layout from '@/Shared/Layout';
 import { Inertia } from '@inertiajs/inertia';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
 import { Router } from '@inertiajs/inertia-vue';


export default {
    metaInfo: {title: 'Subscription'},
    props: ['subscription'],
    layout: Layout,
    components:{

        VueGoodTable,
         
    },
    data() {
    return {

           rows: this.subscription.data,
           columns: [
           {label: 'Date Added', field: 'created_at'},
            {label: 'Producer', field: 'producer'},
            {label: 'Plan Duration(Months)', field: 'plan'},
            {label: 'Start Date', field: 'start_date'},
            {label: 'End Date', field: 'end_date'},

           ]
        }
    },
    mounted() {
             this.rows = this.subscription.data;
     },


     methods: {
        destroy(id){
            if (confirm("Are you sure you want to Delete")) {
                Inertia.delete(route("admin.subscriptions.destroy", id));
                this.subscription.filter(a => a.id !== id);
            }

        }
    }

}
</script>
